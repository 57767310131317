(function(angular) {
	//'use strict';
	angular.module('x-registries')
	.config(['$translateProvider', function($translateProvider) {
		$translateProvider.useSanitizeValueStrategy('escaped');
		$translateProvider.useMissingTranslationHandlerLog();
		$translateProvider.translations('sk', {
			"login.title": "Prihlásenie",
			"login.loginName": "Login",
			"login.password": "Heslo",
			"login.forgottenPassword": "Zabudnuté heslo",
			"login.authentication.failed": "Prihlásenie zlyhalo",
			"login.selected.profile.continue":"Pokračovať s profilom",
			"login.profile":"Profil",
			"label.date": "Dátum",

			"enum.active.A":"Aktívny",
			"enum.active.I":"Neaktívny",

			"enum.boolean.true":"Áno",
			"enum.boolean.false":"Nie",

			"enum.gender.M":"Muž",
			"enum.gender.F":"Žena",

			"validation.field.not.unique":"Hodnota nie je unikátna.",
			"validation.field.not.number":"Hodnota nie je číslo.",
			"validation.field.has.duplicates":"Pole obsahuje duplicity.",
			"validation.field.wrong.format":"Hodnota nemá požadovaný formát.",

			"forgotten.password.title":"Zabudnuté heslo",
			"forgotten.password.email":"Email",
			"forgotten.password.verify":"Kontrola",
			"forgotten.password.email.placeholder":"Registračný email",
			"forgotten.password.identification":"Login",
			"forgotten.password.identification.placeholder":"Identifikačný údaj",
			"forgotten.password.submit":"Odoslať",
			"security.forgotten.token.generated":"Linka pre resetnutie hesla bola zaslaná na Vašu mailovú adresu.",
			"security.forgotten.reset.done":"Nové heslo bolo zaslané na Vašu mailovú adresu.",
			"security.forgotten.captcha.validation.failed.incorrect-captcha-sol":"Captcha nesprávne zadaná.",
			"security.forgotten.token.not.found":"Neplatná požiadavka. Pre zmenu hesla aktualizujte požiadavku.",
			"security.forgotten.mail.not.found": "Uživateľ s daným mailom alebo loginom nebol najdený.",

			"menu.member.title": "Člen",
			"menu.player.title": "Hráč",
			"menu.fees.title": "Poplatok",
			"menu.referee.title": "Rozhodca",
			"menu.coach.title": "Tréner",
			"menu.stadium.title": "Štadión",
			"menu.club.title": "Klub",
			"menu.association.title": "Zväz",
			"menu.person.title": "Osoba",
			"menu.transfer.title": "Zmena kl. príslušnosti",
			"menu.season.title": "Súťažný ročník",
			"menu.age.category.title": "Veková kategória",
			"menu.roster.title": "Súpisky",
			"menu.competition.title": "Súťaž",
			"menu.company.title": "Organizácia",
			"menu.my.profile.title": "Môj profil",
			"menu.permissions.title": "Oprávnenia",
			"menu.schemas.title": "Schémy",

			"menu.new.person.lower.level": "Nová osoba",
			"menu.new.company.lower.level": "Nová spoločnosť",

			"menu.search.person.lower.level": "Hľadať osobu",
			"menu.search.member.lower.level": "Hľadať člena",
			"menu.search.player.lower.level": "Hľadať hráča",
			"menu.search.referee.lower.level": "Hľadať rozhodcu",
			"menu.search.coach.lower.level": "Hľadať trénera",
			"menu.search.company.lower.level": "Hľadať spoločnosť",
			"menu.search.officer.lower.level": "Hľadať funkcionára",
			"menu.search.club.lower.level": "Hľadať klub",
			"menu.search.association.lower.level": "Hľadať zväz",
			"menu.search.statistic.lower.level": "Hľadať štatistika",
			"menu.search.medic.lower.level": "Hľadať zdravotníka",

			"menu.new.lower.level": "Nový",
			"menu.new.lower.level.she": "Nová",
			"menu.search.lower.level": "Hľadať",
			"menu.profile.lower.level": "Profil",
			"menu.dashboard.lower.level": "Dashboard",
			"menu.change.password.lower.level": "Zmena hesla",
			"menu.new.group.lower.level": "Nová skupina",
			"menu.permission.groups.lower.level": "Skupiny oprávnení",
			"menu.user.permissions.lower.level": "Oprávnenia používateľov",
			"menu.schema.list.lower.level": "Zoznam schém",
			"menu.new.profile.lower.level":"Nový bezpečnostný profil",
			"menu.profiles.lower.level":"Hľadať bezpečnostný profil",
			"menu.statistics.title":"Štatistiky",
			"menu.statistics.show.title":"Zobraziť",

			"menu.massmailing.title":"Hromadná pošta",
			"menu.massmailing.new.lower.level":"Nový vzor",
			"menu.massmailing.search.lower.level":"Vyhľadáť",
			"menu.massmailing.send.title":"Odoslať",
			"massmailing.send.confirm":"Skutočne odoslať?",
			"massmailing.send":"Odoslať",
			"massmailing.sent":"Správa bola odoslaná.",

			"massmail.predefined.template": "Preddefinované vzory",
			"massmail.plaintext.template":"Text správy",
			"massmail.subject.template":"Predment správy",
			"massmail.html.template":"Html verzia správy",
			"massmail.search.recipient":"Vyhľadáť príjemcov",
			"massmail.send.view":"Hromadná pošta",
			"massmail.selection.all":"Podľa filtra",

			"header.settings": "Nastavenia",
			"header.log.out": "Odhlásiť sa",
			"personal.change.password.change.password": "Zmena hesla",
			"personal.change.password.current.password": "Staré heslo",
			"personal.change.password.new.password": "Nové heslo",
			"personal.change.password.new.password.check": "Kontrola nového hesla",
//FIXME remove generic search
			"generic.search.searching": "Vyhľadávanie",
			"generic.search.add": "Pridať",
			"generic.search.remove": "Zrušiť",
			"generic.search.removeall": "Zrušiť všetko",
			"generic.search.camelcase": "Uložiť nastavenie filtra",
			"generic.search.removeallfromstorage": "Vymazať nastavenie filtra",
			"generic.search.search": "Hľadať",
			"generic.search.atribute": "Atribút",
			"generic.search.operator": "Operátor",
			"generic.search.value": "Hodnota",
			"generic.search.view": "Zobraziť",
			"generic.search.action": "Akcia",
			"generic.search.result.of.searching": "Výsledok vyhľadávania",
			"generic.search.export.csv": "Exportovať",
			"generic.search.nodata.found":"Neboli najdené žiadne dáta.",
			"generic.noItems.label": "Žiadne položky.",
			"generic.file.choose": "Zvoliť súbor",
			"generic.file.uploadFailed": "Uloženie súboru zlyhalo.",
			"generic.file.uploading": "Nahráva sa",
			"generic.loading": "Načítava...",
			"generic.more": "Viac",
//FIXME translate into other languages
			"queryfilter.ops.EXISTS": "existuje (má hodnotu)",
			"queryfilter.ops.NOT_EXISTS": "neexistuje (nemá hodnotu)",
			"queryfilter.ops.EQUAL": "je rovné (=)",
			"queryfilter.ops.GREATER": "je väčšie (>)",
			"queryfilter.ops.GREATER_EQUAL": "je väčšie alebo rovné (>=)",
			"queryfilter.ops.LESS": "je menšie (<)",
			"queryfilter.ops.LESS_EQUAL": "je menšie alebo rovné (<=)",
			"queryfilter.ops.NOT_EQUAL": "je iné ako",
			"queryfilter.ops.CONTAINS": "obsahuje (s diakritikou)",
			"queryfilter.ops.STARTS_WITH": "začína s (s diakritikou)",
			"queryfilter.ops.CONTAINS_NORMALIZED": "obsahuje (bez diakritiky)",
			"queryfilter.ops.STARTS_WITH_NORMALIZED": "začína s (bez diakritiky)",
			"queryfilter.ops.OR": "alebo",
			"queryfilter.ops.AND": "a",
			"queryfilter.ops.NOT_IN": "neobsahuje žiadny zo záznamov tabulky",
			"queryfilter.ops.IN": "obsahuje aspoň jeden záznam z tabuľky",
			"queryfilter.ops.ALL": "obsahuje všetky záznamy z tabuľky",
			"queryfilter.ops.REGEX": "regulárny výraz",
			"registry.unsuccesfully.saved":"Nepodarilo sa uložiť dáta!",
			"registry.succesfully.saved":"Úspešne uložené",
			"registry.new.new": "Nový",
			"registry.new.cancel": "Zrušiť",
			"registry.new.send": "Odoslať",
			"registry.send": "Odoslať",
			"registry.cancel": "Zrušiť",
			"registry.successfully.generated":"Obsah stránky úspešne vypočítaný",
			"security.group.edit.list.of.security.groups": "Zoznam bezpečnostných skupín",
			"security.group.edit.id": "ID",
			"security.group.edit.edit": "Upraviť",
			"security.group.edit.actions": "Akcie",
			"security.group.edit.save": "Uložiť",
			"security.group.edit.available.permissions": "Dostupné práva",
			"security.group.edit.name": "Názov",
			"security.group.edit.added.permissions": "Pridelené práva",
			"security.group.edit.permissions": "Oprávnenia",
			"security.group.edit.name.of.group": "Názov skupiny",
			"security.group.edit.id.of.group": "Identifikátor skupiny",
			"security.group.edit.modification.of.security.group": "Modifikácia bezpečnostnej skupiny",
			"security.user.edit.modification.done": "Použivateľ bol modifikovaný",
			"security.user.missing.permissions":"Chybajúce oprávnenie: {{data}}",
			"security.user.session.expired":"Pre nečinnosť ste boli odhlásený.",
			"security.current.password.does.not.match": "Heslo sa nepodarilo zmeniť. Staré heslo nie je správne.",
			"security.login.does.not.match": "Login nie je vyplnený.",

			"security.profile.edit.searching":"Bezpečnostný profil vyhľadávanie",
			"security.profile.edit.add":"Pridať",
			"security.profile.edit.search":"Vyhľadávať",
			"security.profile.edit.result":"Výsledok",
			"security.profile.edit.actions":"Akcie",
			"security.profile.edit.name.data":"data",
			"security.profile.edit.name":"Názov",
			"security.profile.edit.groups":"Skupiny",
			"security.profile.edit.added.groups":"Pridelené skupiny",
			"security.profile.edit.available.groups":"Dostupné skupiny",
			"security.profile.edit.permissions":"Oprávnenia ",
			"security.profile.edit.added.permissions":"Pridelené oprávnenia",
			"security.profile.edit.available.permissions":"Dostupné oprávnenia",
			"security.profile.edit.save":"Uložiť",
			"security.profile.edit.cancel":"Zrušiť",
			"security.profile.edit.edit":"Editovať",
			"security.profile.edit.data.criteria":"Filtrácia dát",
			"security.user.edit.profile":"Profily používateľa",
			"security.user.edit.added.profiles":"Pridelené profily",
			"security.user.edit.available.profiles":"Dostupné profily",
			"security.profile.edit.modification.done":"Úspešne uložené",
			"server.side.exception":"Výnimka na strane servera",

			"schema.editor.save": "Uložiť",
			"schema.editor.cancel": "Zrušiť",
			"schema.editor.edit": "Upraviť",
			"schema.editor.id": "ID",
			"schema.editor.name": "Názov",
			"schema.editor.actions": "Akcie",
			"schema.editor.size": "Veľkosť",
			"schema.editor.list.of.schemas.for.editation": "Zoznam schém na editáciu",
			"schema.org.title": "Nová organizácia",
			"schema.stadium.title": "Nový štadión",
			"personal.change.password.passwords.not.equal": "Nové a kontrolné heslo sa nerovnajú!",
			"personal.change.password.password.changed": "Heslo zmenené",
			"personal.change.password.password.not.changed": "Heslo sa nepodarilo zmeniť: {{data}}",
			"registry.successfully.saved":"Úspešne uložené",
			"registry.unsuccessfully.saved":"Nepodarilo sa uložiť dáta!",
			"registry.form.not.filled.correctly":"Formulár nie je správne vyplnený",
			"psui.uploadable.image.unsupported.image.type":"Nepodporovaný formát obrázku",
			"psui.imageresizor.rotate":"otočiť",
			"psui.imageresizor.ok":"ok",
			"psui.uploadable.file.unsupported.file.type":"Nepodporovaný formát súboru",
			"psui.selectbox.loading":"Načítava...",
			"psui.selectbox.rows":"Počet riadkov",
			"date.monday":"Po",
			"date.tuesday":"Ut",
			"date.wednesday":"St",
			"date.thursday":"Št",
			"date.friday":"Pi",
			"date.saturday":"So",
			"date.sunday":"Ne",
			"date.jan":"Jan",
			"date.feb":"Feb",
			"date.mar":"Mar",
			"date.apr":"Apr",
			"date.may":"Máj",
			"date.jun":"Jún",
			"date.jul":"Júl",
			"date.aug":"Aug",
			"date.sep":"Sep",
			"date.oct":"Okt",
			"date.nov":"Nov",
			"date.dec":"Dec",
			"date.current.day":"Dnešný deň",
			"security.user.edit.searching":"Vyhľadávanie",
			"security.user.edit.value":"Hodnota",
			"security.user.edit.operator":"Operátor",
			"security.user.edit.save":"Uložiť",
			"security.user.edit.available.permissions":"Dostupné práva",
			"security.user.edit.added.permissions":"Pridelené práva",
			"security.user.edit.permissions.for.user":"Práva pre používateľa",
			"security.user.edit.available.groups":"Dostupné skupiny",
			"security.user.edit.added.groups":"Pridelené skupiny",
			"security.user.edit.groups.for.users":"Skupiny pre používateľa",
			"security.user.edit.edit":"Upraviť",
			"security.user.edit.actions":"Akcie",
			"security.user.edit.result":"Výsledok",
			"security.user.edit.search":"Hľadať",
			"security.user.edit.add":"Pridať",
			"security.user.edit.attribute":"Atribút",
			"security.user.edit.cancel":"Zrušiť",
			"security.user.edit.reset.password":"Resetovať heslo",
			"security.user.edit.login.data":"Prihlasovacie údaje",
			"security.user.edit.login":"Login",
			"security.user.edit.email":"E-mail",
			"psui-objectlink.btn.save": "Uložiť",
			"psui-objectlink.btn.cancel": "Zrušiť",

			"errors.validation.required": "Povinné pole",
			"errors.validation.objLinkRequired": "Povinné pole",
			"errors.validation.xpsuiArrayUnique": "V tabuľke existujú 2 rovnaké záznamy",
			"errors.validation.xpsuiFormatPattern": "Nesprávny formát",
			"errors.validation.psuiUnique": "Hodnota už v systéme existuje",
			"errors.validation.parse":"Nesprávny formát",
			"errors.validation.valid":"Neplatný formát",
			"schema.editor.schema.editing":"Úprava schémy",
			"security.group.edit.parent.group":"Rodičovská skupina",
			"schemas.group.base.data":"Základné údaje",
			"schemas.group.base.data.ID":"Identifikátor",
			"schemas.group.base.data.name":"Názov skupiny",
			"schemas.group.base.data.parent.group":"Rodičovská skupina",
			"schema.statistics.view":"Štatistiky",
			"schema.statistics.baseData":"Základné",
			"schema.statistics.member.count":"Počet členov",
			"schema.statistics.women.count":"Počet žien",
			"schema.statistics.fees":"Poplatky",
			"schema.statistics.fees.expected.count":"Počet vystavených",
			"schema.statistics.fees.expected.sum":"Suma vystavených",
			"schema.statistics.fees.paid.count":"Počet zaplatených",
			"schema.statistics.fees.paid.sum":"Suma zaplatených",
			"schema.statistics.fees.overdue.count":"Počet po splatnosti",
			"schema.statistics.fees.overdue.sum":"Suma po splatnosti",
			"schema.requests.basedata": "Základné údaje",
			"schema.requests.basedata": "Prílohy",

			"schemas.countries.enum.AFG": "Afganistan",
			"schemas.countries.enum.ALA": "Alandy",
			"schemas.countries.enum.ALB": "Albánsko",
			"schemas.countries.enum.DZA": "Alžírsko",
			"schemas.countries.enum.ASM": "Americká Samoa",
			"schemas.countries.enum.VIR": "Americké Panenské ostrovy",
			"schemas.countries.enum.AND": "Andorra",
			"schemas.countries.enum.AGO": "Angola",
			"schemas.countries.enum.AIA": "Anguilla",
			"schemas.countries.enum.ATA": "Antarktída",
			"schemas.countries.enum.ATG": "Antigua a Barbuda",
			"schemas.countries.enum.ARG": "Argentína",
			"schemas.countries.enum.ARM": "Arménsko",
			"schemas.countries.enum.ABW": "Aruba",
			"schemas.countries.enum.AUS": "Austrália",
			"schemas.countries.enum.AZE": "Azerbajdžan",
			"schemas.countries.enum.BHS": "Bahamy",
			"schemas.countries.enum.BHR": "Bahrajn",
			"schemas.countries.enum.BGD": "Bangladéš",
			"schemas.countries.enum.BRB": "Barbados",
			"schemas.countries.enum.BEL": "Belgicko",
			"schemas.countries.enum.BLZ": "Belize",
			"schemas.countries.enum.BLR": "Bielorusko",
			"schemas.countries.enum.BEN": "Benin",
			"schemas.countries.enum.BMU": "Bermudy",
			"schemas.countries.enum.BTN": "Bhután",
			"schemas.countries.enum.BOL": "Bolívia",
			"schemas.countries.enum.BES": "Bonaire, Svätý Eustatius a Saba",
			"schemas.countries.enum.BIH": "Bosna a Hercegovina",
			"schemas.countries.enum.BWA": "Botswana",
			"schemas.countries.enum.BVT": "Bouvetov ostrov",
			"schemas.countries.enum.BRA": "Brazília",
			"schemas.countries.enum.IOT": "Britské indickooceánske územie",
			"schemas.countries.enum.VGB": "Britské Panenské ostrovy",
			"schemas.countries.enum.BRN": "Brunej",
			"schemas.countries.enum.BGR": "Bulharsko",
			"schemas.countries.enum.BFA": "Burkina Faso",
			"schemas.countries.enum.BDI": "Burundi",
			"schemas.countries.enum.COK": "Cookove ostrovy",
			"schemas.countries.enum.CUW": "Curaçao",
			"schemas.countries.enum.TCD": "Čad",
			"schemas.countries.enum.MNE": "Čierna Hora",
			"schemas.countries.enum.CZE": "Česko",
			"schemas.countries.enum.CHN": "Čína",
			"schemas.countries.enum.DNK": "Dánsko",
			"schemas.countries.enum.COD": "Kongo",
			"schemas.countries.enum.DMA": "Dominika",
			"schemas.countries.enum.DOM": "Dominikánska republika",
			"schemas.countries.enum.DJI": "Džibutsko",
			"schemas.countries.enum.EGY": "Egypt",
			"schemas.countries.enum.ECU": "Ekvádor",
			"schemas.countries.enum.ERI": "Eritrea",
			"schemas.countries.enum.EST": "Estónsko",
			"schemas.countries.enum.ETH": "Etiópia",
			"schemas.countries.enum.FRO": "Faerské ostrovy",
			"schemas.countries.enum.FLK": "Falklandy",
			"schemas.countries.enum.FJI": "Fidži",
			"schemas.countries.enum.PHL": "Filipíny",
			"schemas.countries.enum.FIN": "Fínsko",
			"schemas.countries.enum.FRA": "Francúzsko",
			"schemas.countries.enum.GUF": "Francúzska Guyana",
			"schemas.countries.enum.ATF": "Francúzske južné a antarktické územia",
			"schemas.countries.enum.PYF": "Francúzska Polynézia",
			"schemas.countries.enum.GAB": "Gabon",
			"schemas.countries.enum.GMB": "Gambia",
			"schemas.countries.enum.GHA": "Ghana",
			"schemas.countries.enum.GIB": "Gibraltár",
			"schemas.countries.enum.GRD": "Grenada",
			"schemas.countries.enum.GRL": "Grónsko",
			"schemas.countries.enum.GEO": "Gruzínsko",
			"schemas.countries.enum.GLP": "Guadeloupe",
			"schemas.countries.enum.GUM": "Guam",
			"schemas.countries.enum.GTM": "Guatemala",
			"schemas.countries.enum.GGY": "Guernsey",
			"schemas.countries.enum.GIN": "Guinea",
			"schemas.countries.enum.GNB": "Guinea-Bissau",
			"schemas.countries.enum.GUY": "Guyana",
			"schemas.countries.enum.HTI": "Haiti",
			"schemas.countries.enum.HMD": "Heardov ostrov a MacDonaldová ostrovy",
			"schemas.countries.enum.HND": "Honduras",
			"schemas.countries.enum.HKG": "Hongkong",
			"schemas.countries.enum.CHL": "Čile",
			"schemas.countries.enum.HRV": "Chorvátsko",
			"schemas.countries.enum.IND": "India",
			"schemas.countries.enum.IDN": "Indonézia",
			"schemas.countries.enum.IRQ": "Irak",
			"schemas.countries.enum.IRN": "Irán",
			"schemas.countries.enum.IRL": "Írsko",
			"schemas.countries.enum.ISL": "Island",
			"schemas.countries.enum.ITA": "Taliansko",
			"schemas.countries.enum.ISR": "Izrael",
			"schemas.countries.enum.JAM": "Jamajka",
			"schemas.countries.enum.JPN": "Japonsko",
			"schemas.countries.enum.YEM": "Jemen",
			"schemas.countries.enum.JEY": "Jersey",
			"schemas.countries.enum.ZAF": "Južná Afrika",
			"schemas.countries.enum.SGS": "Južná Georgia a Južné Sandwichove ostrovy",
			"schemas.countries.enum.SSD": "Južný Sudán",
			"schemas.countries.enum.JOR": "Jordánsko",
			"schemas.countries.enum.CYM": "Kajmanie ostrovy",
			"schemas.countries.enum.KHM": "Kambodža",
			"schemas.countries.enum.CMR": "Kamerun",
			"schemas.countries.enum.CAN": "Kanada",
			"schemas.countries.enum.CPV": "Kapverdy",
			"schemas.countries.enum.QAT": "Katar",
			"schemas.countries.enum.KAZ": "Kazachstan",
			"schemas.countries.enum.KEN": "Keňa",
			"schemas.countries.enum.KIR": "Kiribati",
			"schemas.countries.enum.CCK": "Kokosové (Keelingove) ostrovy",
			"schemas.countries.enum.COL": "Kolumbia",
			"schemas.countries.enum.COM": "Komory",
			"schemas.countries.enum.COG": "Konžská republika",
			"schemas.countries.enum.PRK": "Kórejská ľudovodemokratická republika",
			"schemas.countries.enum.KOR": "Kórejská republika",
			"schemas.countries.enum.XKO": "Kosovo",
			"schemas.countries.enum.CRI": "Kostarika",
			"schemas.countries.enum.CUB": "Kuba",
			"schemas.countries.enum.KWT": "Kuvajt",
			"schemas.countries.enum.CYP": "Cyprus",
			"schemas.countries.enum.KGZ": "Kirgizsko",
			"schemas.countries.enum.LAO": "Laos",
			"schemas.countries.enum.LSO": "Lesotho",
			"schemas.countries.enum.LBN": "Libanon",
			"schemas.countries.enum.LBR": "Libéria",
			"schemas.countries.enum.LBY": "Líbya",
			"schemas.countries.enum.LIE": "Lichtenštajnsko",
			"schemas.countries.enum.LTU": "Litva",
			"schemas.countries.enum.LVA": "Lotyšsko",
			"schemas.countries.enum.LUX": "Luxembursko",
			"schemas.countries.enum.MAC": "Macao",
			"schemas.countries.enum.MDG": "Madagaskar",
			"schemas.countries.enum.HUN": "Maďarsko",
			"schemas.countries.enum.MKD": "Macedónsko",
			"schemas.countries.enum.MYS": "Malajzia",
			"schemas.countries.enum.MWI": "Malawi",
			"schemas.countries.enum.MDV": "Maldivy",
			"schemas.countries.enum.MLI": "Mali",
			"schemas.countries.enum.MLT": "Malta",
			"schemas.countries.enum.IMN": "Man",
			"schemas.countries.enum.MAR": "Maroko",
			"schemas.countries.enum.MHL": "Marshallove ostrovy",
			"schemas.countries.enum.MTQ": "Martinik",
			"schemas.countries.enum.MUS": "Maurícius",
			"schemas.countries.enum.MRT": "Mauritánia",
			"schemas.countries.enum.MYT": "Mayotte",
			"schemas.countries.enum.UMI": "Menšie odľahlé ostrovy USA",
			"schemas.countries.enum.MEX": "Mexiko",
			"schemas.countries.enum.FSM": "Mikronézia",
			"schemas.countries.enum.MDA": "Moldavsko",
			"schemas.countries.enum.MCO": "Monako",
			"schemas.countries.enum.MNG": "Mongolsko",
			"schemas.countries.enum.MSR": "Montserrat",
			"schemas.countries.enum.MOZ": "Mozambik",
			"schemas.countries.enum.MMR": "Mjanmarsko",
			"schemas.countries.enum.NAM": "Namíbia",
			"schemas.countries.enum.NRU": "Nauru",
			"schemas.countries.enum.DEU": "Nemecko",
			"schemas.countries.enum.NPL": "Nepál",
			"schemas.countries.enum.NER": "Niger",
			"schemas.countries.enum.NGA": "Nigéria",
			"schemas.countries.enum.NIC": "Nikaragua",
			"schemas.countries.enum.NIU": "Niue",
			"schemas.countries.enum.NLD": "Holandsko",
			"schemas.countries.enum.NFK": "Norfolk",
			"schemas.countries.enum.NOR": "Nórsko",
			"schemas.countries.enum.NCL": "Nová Kaledónia",
			"schemas.countries.enum.NZL": "Nový Zéland",
			"schemas.countries.enum.OMN": "Omán",
			"schemas.countries.enum.PAK": "Pakistan",
			"schemas.countries.enum.PLW": "Palau",
			"schemas.countries.enum.PSE": "Palestína",
			"schemas.countries.enum.PAN": "Panama",
			"schemas.countries.enum.PNG": "Papua Nová Guinea",
			"schemas.countries.enum.PRY": "Paraguaj",
			"schemas.countries.enum.PER": "Peru",
			"schemas.countries.enum.PCN": "Pitcairn",
			"schemas.countries.enum.CIV": "Pobrežie Slonoviny",
			"schemas.countries.enum.POL": "Poľsko",
			"schemas.countries.enum.PRI": "Portoriko",
			"schemas.countries.enum.PRT": "Portugalsko",
			"schemas.countries.enum.AUT": "Rakúsko",
			"schemas.countries.enum.REU": "Réunion",
			"schemas.countries.enum.GNQ": "Rovníková Guinea",
			"schemas.countries.enum.ROU": "Rumunsko",
			"schemas.countries.enum.RUS": "Rusko",
			"schemas.countries.enum.RWA": "Rwanda",
			"schemas.countries.enum.GRC": "Grécko",
			"schemas.countries.enum.SPM": "Saint Pierre a Miquelon",
			"schemas.countries.enum.SLV": "Salvador",
			"schemas.countries.enum.WSM": "Samoa",
			"schemas.countries.enum.SMR": "San Marino",
			"schemas.countries.enum.SAU": "Saudská Arábia",
			"schemas.countries.enum.SEN": "Senegal",
			"schemas.countries.enum.MNP": "Severné Mariány",
			"schemas.countries.enum.SYC": "Seychely",
			"schemas.countries.enum.SLE": "Sierra Leone",
			"schemas.countries.enum.SGP": "Singapur",
			"schemas.countries.enum.SVK": "Slovensko",
			"schemas.countries.enum.SVN": "Slovinsko",
			"schemas.countries.enum.SOM": "Somálsko",
			"schemas.countries.enum.ARE": "Spojené arabské emiráty",
			"schemas.countries.enum.USA": "Spojené štáty",
			"schemas.countries.enum.SRB": "Srbsko",
			"schemas.countries.enum.CAF": "Stredoafrická republika",
			"schemas.countries.enum.SDN": "Sudán",
			"schemas.countries.enum.SUR": "Surinam",
			"schemas.countries.enum.SHN": "Svätá Helena",
			"schemas.countries.enum.LCA": "Svätá Lucia",
			"schemas.countries.enum.BLM": "Svätý Bartolomej",
			"schemas.countries.enum.KNA": "Svätý Krištof a Nevis",
			"schemas.countries.enum.MAF": "Svätý Martin (FR)",
			"schemas.countries.enum.SXM": "Svätý Martin (NL)",
			"schemas.countries.enum.STP": "Svätý Tomáš a Princov ostrov",
			"schemas.countries.enum.VCT": "Svätý Vincent a Grenadíny",
			"schemas.countries.enum.SWZ": "Svazijsko",
			"schemas.countries.enum.SYR": "Sýria",
			"schemas.countries.enum.SLB": "Šalamúnove ostrovy",
			"schemas.countries.enum.ESP": "Španielsko",
			"schemas.countries.enum.SJM": "Špicbergy a Jan Mayen",
			"schemas.countries.enum.LKA": "Srí Lanka",
			"schemas.countries.enum.SWE": "Švédsko",
			"schemas.countries.enum.CHE": "Švajčiarsko",
			"schemas.countries.enum.TJK": "Tadžikistan",
			"schemas.countries.enum.TZA": "Tanzánia",
			"schemas.countries.enum.THA": "Thajsko",
			"schemas.countries.enum.TWN": "Taiwan",
			"schemas.countries.enum.TGO": "Togo",
			"schemas.countries.enum.TKL": "Tokelau",
			"schemas.countries.enum.TON": "Tonga",
			"schemas.countries.enum.TTO": "Trinidad a Tobago",
			"schemas.countries.enum.TUN": "Tunisko",
			"schemas.countries.enum.TUR": "Turecko",
			"schemas.countries.enum.TKM": "Turkménsko",
			"schemas.countries.enum.TCA": "Turks a Caicos",
			"schemas.countries.enum.TUV": "Tuvalu",
			"schemas.countries.enum.UGA": "Uganda",
			"schemas.countries.enum.UKR": "Ukrajina",
			"schemas.countries.enum.URY": "Uruguaj",
			"schemas.countries.enum.UZB": "Uzbekistan",
			"schemas.countries.enum.CXR": "Vianočný ostrov",
			"schemas.countries.enum.VUT": "Vanuatu",
			"schemas.countries.enum.VAT": "Vatikán",
			"schemas.countries.enum.GBR": "Veľká Británia",
			"schemas.countries.enum.VEN": "Venezuela",
			"schemas.countries.enum.VNM": "Vietnam",
			"schemas.countries.enum.TLS": "Východný Timor",
			"schemas.countries.enum.WLF": "Wallis a Futuna",
			"schemas.countries.enum.ZMB": "Zambia",
			"schemas.countries.enum.ESH": "Západná Sahara",
			"schemas.countries.enum.ZWE": "Zimbabwe",

			"dashboard.widget.notifications.title": "Notifikácie",
			"dashboard.widget.members.openRequests": "Žiadosti o registráciu",
			"dashboard.widget.data.openRequests":"Žiadosti o zmenu údajov",
			"dashboard.widget.transfer.openRequests":"Žiadosti o transfer",
			"dashboard.widget.transferSolverKM.openRequests":"Žiadosti o transfer z klubu",
			"dashboard.widget.members.noOpenRequests": "Žiadne žiadosti.",
			"dashboard.widget.data.noOpenRequests": "Žiadne žiadosti.",
			"dashboard.widget.transfer.noOpenRequests": "Žiadne žiadosti.",

			"competition.part.generated.matches": "Vygenerované zápasy",

			"portal.edit.title":"Portálový editor",
			"portal.edit.edit":"Editovať",
			"portal.edit.new":"Nový",
			"portal.edit.add.block":"Pridať blok",
			"portal.edit.cancel":"Zrušiť",
			"portal.edit.save":"Uložiť",
			"portal.edit.new.name":"Názov:",
			"portal.edit.new.template":"Template:",
			"portal.edit.new.publish":"Publikovať:",
			"portal.edit.new.publishFrom":"Publikovať od:",
			"portal.edit.new.tags":"Tagy:",
			"portal.edit.block.choice":"Výber bloku",
			"portal.edit.block.description":"Popis:",
			"portal.menu.title":"Portálové menu",
			"portal.menu.cancel":"Zrušiť",
			"portal.menu.send":"Odoslať"
		});

		$translateProvider.translations('cz', {
			"login.title": "Přihlášení",
			"login.loginName": "Login",
			"login.password": "Heslo",
			"login.forgottenPassword": "Zapomenuté heslo",
			"login.authentication.failed": "Přihlášení selhalo",
			"login.selected.profile.continue":"Pokračovat s profilem",
			"login.profile":"Profil",
			"label.date": "Datum",

			"enum.active.A":"Aktívny",
			"enum.active.I":"Neaktívny",

			"enum.boolean.true":"Áno",
			"enum.boolean.false":"Nie",

			"enum.gender.M":"Muž",
			"enum.gender.F":"Žena",

			"validation.field.not.unique":"Hodnota není unikátní.",
			"validation.field.not.number":"Hodnota není číslo.",
			"validation.field.has.duplicates":"Pole obsahuje duplicity.",
			"validation.field.wrong.format":"Hodnota není v požadovaném formátu.",

			"forgotten.password.title":"Zabudnuté heslo",
			"forgotten.password.email":"Email",
			"forgotten.password.verify":"Kontrola",
			"forgotten.password.email.placeholder":"Registrační email",
			"forgotten.password.identification":"Login",
			"forgotten.password.identification.placeholder":"Identifikační údaj",
			"forgotten.password.submit":"Odeslat",
			"security.forgotten.token.generated":"Linka pre resetnutie hesla bola zaslaná na Vašu mailovú adresu.",
			"security.forgotten.reset.done":"Nové heslo bolo zaslané na Vašu mailovú adresu.",
			"security.forgotten.captcha.validation.failed.incorrect-captcha-sol":"Captcha nesprávne zadaná.",
			"security.forgotten.token.not.found":"Neplatná požiadavka. Pre zmenu hesla aktualizujte požiadavku.",
			"security.forgotten.mail.not.found": "Uživatel s daným mailom alebo loginom nebol nalezen.",


			"menu.member.title": "Člen",
			"menu.player.title": "Hráč",
			"menu.fees.title": "Poplatek",
			"menu.referee.title": "Rozhodca",
			"menu.coach.title": "Tréner",
			"menu.stadium.title": "Štadión",
			"menu.club.title": "Klub",
			"menu.association.title": "Zväz",
			"menu.person.title": "Osoba",
			"menu.transfer.title": "Zmena kl. príslušnosti",
			"menu.season.title": "Súťažný ročník",
			"menu.age.category.title": "Veková kategória",
			"menu.roster.title": "Súpisky",
			"menu.competition.title": "Súťaž",
			"menu.company.title": "Organizace",
			"menu.my.profile.title": "Můj profil",
			"menu.permissions.title": "Oprávnění",
			"menu.schemas.title": "Schémata",

			"menu.new.person.lower.level": "Nová osoba",
			"menu.new.company.lower.level": "Nová společnosť",

			"menu.search.person.lower.level": "Hledat osobu",
			"menu.search.member.lower.level": "Hledat člena",
			"menu.search.player.lower.level": "Hledat hráča",
			"menu.search.referee.lower.level": "Hledat rozhodcu",
			"menu.search.statistic.lower.level": "Hledat statistika",
			"menu.search.officer.lower.level": "Hľadať funkcionára",
			"menu.search.medic.lower.level": "Hledat zdravotníka",
			"menu.search.coach.lower.level": "Hledat trenéra",
			"menu.search.company.lower.level": "Hledat spoločnosť",
			"menu.search.club.lower.level": "Hledat klub",
			"menu.search.association.lower.level": "Hledat zväz",

			"menu.new.lower.level": "Nový",
			"menu.new.lower.level.she": "Nová",
			"menu.search.lower.level": "Hledat",
			"menu.profile.lower.level": "Profil",
			"menu.dashboard.lower.level": "Dashboard",
			"menu.change.password.lower.level": "Změna hesla",
			"menu.new.group.lower.level": "Nová skupina",
			"menu.permission.groups.lower.level": "Skupiny oprávnění",
			"menu.user.permissions.lower.level": "Oprávnění uživatelů",
			"menu.schema.list.lower.level": "Seznam schémat",
			"menu.new.profile.lower.level":"Nový bezpečnostnej profil",
			"menu.profiles.lower.level":"Hledat bezpečnostnej profil",
			"menu.statistics.title":"Statistiky",
			"menu.statistics.show.title":"Zobrazit",
			"menu.massmailing.title":"Hromadní pošta",
			"menu.massmailing.new.lower.level":"Nový vzor",
			"menu.massmailing.search.lower.level":"Vyhledat vzor",
			"menu.massmailing.send.title":"Odeslat",
			"massmail.predefined.template": "Předdefinované vzory",
			"massmail.plaintext.template":"Text zprávy",
			"massmail.subject.template":"Predment zprávy",
			"massmail.html.template":"Html verze zprávy",
			"massmail.search.recipient":"Vyhledat příjemce",
			"massmail.send.view":"Hromadní pošta",
			"massmailing.send.confirm":"Skutečne odeslat?",
			"massmailing.send":"Odeslat",
			"massmailing.sent":"Správa byla odeslaná.",
			"massmail.selection.all":"Podle filtra",
			"header.settings": "Nastavení",
			"header.log.out": "Odhlásit se",
			"personal.change.password.change.password": "Změna hesla",
			"personal.change.password.current.password": "Staré heslo",
			"personal.change.password.new.password": "Nové heslo",
			"personal.change.password.new.password.check": "Kontrola nového hesla",
			"generic.search.searching": "Vyhledávání",
			"generic.search.add": "Přidat",
			"generic.search.remove": "Zrušit",
			"generic.search.removeall": "Zrušit vše",
			"generic.search.camelcase": "Uložit nastavení filtru",
			"generic.search.removeallfromstorage": "Vymazat nastavení filtru",
			"generic.search.search": "Hledat",
			"generic.search.atribute": "Atribut",
			"generic.search.operator": "Operátor",
			"generic.search.value": "Hodnota",
			"generic.search.view": "Zobrazit",
			"generic.search.action": "Akce",
			"generic.search.result.of.searching": "Výsledek vyhledávání",
			"generic.search.export.csv": "Exportovat",
			"generic.search.nodata.found":"Data neboli nalezeny.",
			"generic.noItems.label": "Žádné položky.",
			"generic.file.choose": "Vybrat soubor",
			"generic.file.uploadFailed": "Nepodařilo se uložit soubor.",
			"generic.file.uploading": "Nahrává se",
			"generic.loading": "Načítava...",
			"generic.more": "Více",

			"queryfilter.ops.EXISTS": "existuje (má hodnotu)",
			"queryfilter.ops.NOT_EXISTS": "neexistuje (nemá hodnotu)",
			"queryfilter.ops.EQUAL": "je rovné (=)",
			"queryfilter.ops.GREATER": "je větší (>)",
			"queryfilter.ops.GREATER_EQUAL": "je větší nebo rovno (>=)",
			"queryfilter.ops.LESS": "je menší (<)",
			"queryfilter.ops.LESS_EQUAL": "je menší nebo rovno (<=)",
			"queryfilter.ops.NOT_EQUAL": "je jiné než",
			"queryfilter.ops.CONTAINS": "obsahuje (s diakritikou)",
			"queryfilter.ops.STARTS_WITH": "začíná s (s diakritikou)",
			"queryfilter.ops.CONTAINS_NORMALIZED": "obsahuje (bez diakritiky)",
			"queryfilter.ops.STARTS_WITH_NORMALIZED": "začína s (bez diakritiky)",
			"queryfilter.ops.OR": "nebo",
			"queryfilter.ops.AND": "a",
			"queryfilter.ops.NOT_IN": "neobsahuje žádný ze záznamů tabulky",
			"queryfilter.ops.IN": "obsahuje alespoň jeden záznam z tabulky",
			"queryfilter.ops.ALL": "obsahuje všechny záznamy z tabulky",
			"queryfilter.ops.REGEX": "regulárny výraz",

			"registry.unsuccesfully.saved": "Nepodařilo se uložit data!",
			"registry.succesfully.saved": "Úspěšně uloženo",
			"registry.new.new": "Nový",
			"registry.new.cancel": "Zrušit",
			"registry.new.send": "Odeslat",
			"registry.send": "Odeslat",
			"registry.cancel": "Zrušit",
			"registry.successfully.generated":"Obsah úspešne vygenerovaný",
			"security.group.edit.list.of.security.groups": "Seznam bezpečnostních skupin",
			"security.group.edit.id": "ID",
			"security.group.edit.edit": "Upravit",
			"security.group.edit.actions": "Akce",
			"security.group.edit.save": "Uložit",
			"security.group.edit.available.permissions": "Dostupné práva",
			"security.group.edit.name": "Název",
			"security.group.edit.added.permissions": "Přidělená práva",
			"security.group.edit.permissions": "Oprávnění",
			"security.group.edit.name.of.group": "Název skupiny",
			"security.group.edit.id.of.group": "Identifikátor skupiny",
			"security.group.edit.modification.of.security.group": "Modifikace bezpečnostní skupiny",
			"security.user.edit.modification.done": "Modifikace uživatela",
			"security.user.session.expired":"Relace vypršela",
			"security.current.password.does.not.match": "Heslo sa nepodařilo změnit. Staré heslo není spravné.",
			"security.login.does.not.match": "Přihlášení není vyplněno.",

			"security.profile.edit.searching":"Bezpečnostný profil vyhledávání",
			"security.profile.edit.add":"Pridat",
			"security.profile.edit.search":"Vyhledat",
			"security.profile.edit.result":"Výsledek vyhledávání",
			"security.profile.edit.actions":"Akce",
			"security.profile.edit.name.data":"data",
			"security.profile.edit.name":"Název",
			"security.profile.edit.groups":"Skupiny",
			"security.profile.edit.added.groups":"Pridelené skupiny",
			"security.profile.edit.available.groups":"Dostupné skupiny",
			"security.profile.edit.permissions":"Oprávnení",
			"security.profile.edit.added.permissions":"Pridelené oprávnění",
			"security.profile.edit.available.permissions":"Dostupné oprávnění",
			"security.profile.edit.save":"uložit",
			"security.profile.edit.cancel":"Zrušit",
			"security.profile.edit.edit":"Editovat",
			"security.profile.edit.data.criteria":"Filtrace dát",
			"security.user.edit.profile":"Profily používateľa",
			"security.user.edit.added.profiles":"Pridelené profily",
			"security.user.edit.available.profiles":"Dostupné profily",
			"server.side.exception":"Výnimka na strane servera",

			"schema.editor.save": "Uložit",
			"schema.editor.cancel": "Zrušiť",
			"schema.editor.edit": "Upravit",
			"schema.editor.id": "ID ",
			"schema.editor.name": "Název",
			"schema.editor.actions": "Akce",
			"schema.editor.size": "Velikost",
			"schema.editor.list.of.schemas.for.editation": "Seznam schémat na editaci",
			"schema.editor.schema.editing":"Úprava schemata",
			"personal.change.password.passwords.not.equal": "Nové a kontrolní heslo se nerovnají!",
			"personal.change.password.password.changed": "Heslo změněno",
			"personal.change.password.password.not.changed": "Heslo se nepodařilo změnit",
			"registry.successfully.saved": "Úspěšně uloženo",
			"registry.unsuccessfully.saved": "Nepodařilo se uložit data!",
			"registry.form.not.filled.correctly": "Formulář není správně vyplněný",
			"psui.uploadable.image.unsupported.image.type": "Nepodporovaný formát obrázku",
			"psui.imageresizor.rotate": "otočit",
			"psui.imageresizor.ok": "ok",
			"psui.uploadable.file.unsupported.file.type":"Nepodporovaný formát souboru",
			"psui.selectbox.loading":"Načítava...",
			"psui.selectbox.rows":"Počet riadkov",
			"date.monday": "Po",
			"date.tuesday": "Út",
			"date.wednesday": "St",
			"date.thursday": "Čt",
			"date.friday": "Pá",
			"date.saturday": "So",
			"date.sunday": "Ne",
			"date.jan": "Led",
			"date.feb": "Úno",
			"date.mar": "Bře",
			"date.apr": "Dub",
			"date.may": "Kvě",
			"date.jun": "Čvn",
			"date.jul": "Čvc",
			"date.aug": "Srp",
			"date.sep": "Zář",
			"date.oct": "Řjn",
			"date.nov": "Lis",
			"date.dec": "Pro",
			"date.current.day": "Dnešní den",
			"security.user.edit.searching": "Vyhledávání",
			"security.user.edit.value": "Hodnota",
			"security.user.edit.operator": "Operátor",
			"security.user.edit.save": "Uložit",
			"security.user.edit.available.permissions": "Dostupná práva",
			"security.user.edit.added.permissions": "Přidělená práva",
			"security.user.edit.permissions.for.user": "Práva pro uživatele",
			"security.user.edit.available.groups": "Dostupné skupiny",
			"security.user.edit.added.groups": "Přidělené skupiny",
			"security.user.edit.groups.for.users": "Skupiny pro uživatele",
			"security.user.edit.edit": "Upravit",
			"security.user.edit.actions": "Akce",
			"security.user.edit.result": "Výsledek",
			"security.user.edit.search": "Hledat",
			"security.user.edit.add": "Přidat",
			"security.user.edit.attribute": "Atribut",
			"security.user.edit.cancel": "Zrušit",
			"security.user.edit.reset.password":"Resetovat heslo",
			"security.user.missing.permissions":"Nedostatečná práva {{data}}",
			"errors.validation.required": "Povinné pole",
			"errors.validation.objLinkRequired": "Povinné pole",
			"errors.validation.xpsuiArrayUnique": "V tabulce existují 2 stejné záznamy",
			"errors.validation.xpsuiFormatPattern": "Nesprávný format",
			"errors.validation.psuiUnique": "Hodnota už v systéme existuje",
			"errors.validation.parse":"Nesprávný formát",
			"errors.validation.valid":"Neplatný formát",
			"security.user.edit.login.data":"Přihlašovací údaje",
			"security.user.edit.login":"Login",
			"security.user.edit.email":"E-mail",
			"psui-objectlink.btn.save": "Uložit",
			"psui-objectlink.btn.cancel": "Zrušit",
			"security.group.edit.parent.group":"Rodičovská skupina",
			"schemas.group.base.data":"Základní údaje",
			"schemas.group.base.data.ID":"Identifikátor",
			"schemas.group.base.data.name":"Jméno skupiny",
			"schemas.group.base.data.parent.group":"Rodičovská skupina",
			"schema.statistics.view":"Statistiky",
			"schema.statistics.baseData":"Základní",
			"schema.statistics.member.count":"Počet členov",
			"schema.statistics.women.count":"Počet žien",
			"schema.statistics.fees":"Poplatky",
			"schema.statistics.fees.expected.count":"Počet vystavených",
			"schema.statistics.fees.expected.sum":"Suma vystavených",
			"schema.statistics.fees.paid.count":"Počet zaplatených",
			"schema.statistics.fees.paid.sum":"Suma zplatených",
			"schema.statistics.fees.overdue.count":"Počet po splatnosti",
			"schema.statistics.fees.overdue.sum":"Suma po splatnosti",	"schemas.countries.enum.AFG": "Afganistan",
			"schema.requests.basedata": "Základné údaje",
			"schema.requests.basedata": "Prílohy",

			"schemas.countries.enum.ALA": "Alandy",
			"schemas.countries.enum.ALB":"Albánie",
			"schemas.countries.enum.DZA":"Alžírsko",
			"schemas.countries.enum.ASM":"Americká Samoa",
			"schemas.countries.enum.VIR":"Americké Panenské ostrovy",
			"schemas.countries.enum.AND":"Andorra",
			"schemas.countries.enum.AGO":"Angola",
			"schemas.countries.enum.AIA":"Anguilla",
			"schemas.countries.enum.ATA":"Antarktida",
			"schemas.countries.enum.ATG":"Antigua a Barbuda",
			"schemas.countries.enum.ARG":"Argentina",
			"schemas.countries.enum.ARM":"Arménie",
			"schemas.countries.enum.ABW":"Aruba",
			"schemas.countries.enum.AUS":"Austrálie",
			"schemas.countries.enum.AZE":"Ázerbájdžán",
			"schemas.countries.enum.BHS":"Bahamy",
			"schemas.countries.enum.BHR":"Bahrajn",
			"schemas.countries.enum.BGD":"Bangladéš",
			"schemas.countries.enum.BRB":"Barbados",
			"schemas.countries.enum.BEL":"Belgie",
			"schemas.countries.enum.BLZ":"Belize",
			"schemas.countries.enum.BLR":"Bělorusko",
			"schemas.countries.enum.BEN":"Benin",
			"schemas.countries.enum.BMU":"Bermudy",
			"schemas.countries.enum.BTN":"Bhútán",
			"schemas.countries.enum.BOL":"Bolívie",
			"schemas.countries.enum.BES":"Bonaire, Svatý Eustach a Saba",
			"schemas.countries.enum.BIH":"Bosna a Hercegovina",
			"schemas.countries.enum.BWA":"Botswana",
			"schemas.countries.enum.BVT":"Bouvetův ostrov",
			"schemas.countries.enum.BRA":"Brazílie",
			"schemas.countries.enum.IOT":"Britské indickooceánské území",
			"schemas.countries.enum.VGB":"Britské Panenské ostrovy",
			"schemas.countries.enum.BRN":"Brunej",
			"schemas.countries.enum.BGR":"Bulharsko",
			"schemas.countries.enum.BFA":"Burkina Faso",
			"schemas.countries.enum.BDI":"Burundi",
			"schemas.countries.enum.COK":"Cookovy ostrovy",
			"schemas.countries.enum.CUW":"Curaçao",
			"schemas.countries.enum.TCD":"Čad",
			"schemas.countries.enum.MNE":"Černá Hora",
			"schemas.countries.enum.CZE":"Česko",
			"schemas.countries.enum.CHN":"Čína",
			"schemas.countries.enum.DNK":"Dánsko",
			"schemas.countries.enum.COD":"Demokratická republika Kongo",
			"schemas.countries.enum.DMA":"Dominika",
			"schemas.countries.enum.DOM":"Dominikánská republika",
			"schemas.countries.enum.DJI":"Džibutsko",
			"schemas.countries.enum.EGY":"Egypt",
			"schemas.countries.enum.ECU":"Ekvádor",
			"schemas.countries.enum.ERI":"Eritrea",
			"schemas.countries.enum.EST":"Estonsko",
			"schemas.countries.enum.ETH":"Etiopie",
			"schemas.countries.enum.FRO":"Faerské ostrovy",
			"schemas.countries.enum.FLK":"Falklandy",
			"schemas.countries.enum.FJI":"Fidži",
			"schemas.countries.enum.PHL":"Filipíny",
			"schemas.countries.enum.FIN":"Finsko",
			"schemas.countries.enum.FRA":"Francie",
			"schemas.countries.enum.GUF":"Francouzská Guyana",
			"schemas.countries.enum.ATF":"Francouzská jižní a antarktická území",
			"schemas.countries.enum.PYF":"Francouzská Polynésie",
			"schemas.countries.enum.GAB":"Gabon",
			"schemas.countries.enum.GMB":"Gambie",
			"schemas.countries.enum.GHA":"Ghana",
			"schemas.countries.enum.GIB":"Gibraltar",
			"schemas.countries.enum.GRD":"Grenada",
			"schemas.countries.enum.GRL":"Grónsko",
			"schemas.countries.enum.GEO":"Gruzie",
			"schemas.countries.enum.GLP":"Guadeloupe",
			"schemas.countries.enum.GUM":"Guam",
			"schemas.countries.enum.GTM":"Guatemala",
			"schemas.countries.enum.GGY":"Guernsey",
			"schemas.countries.enum.GIN":"Guinea",
			"schemas.countries.enum.GNB":"Guinea-Bissau",
			"schemas.countries.enum.GUY":"Guyana",
			"schemas.countries.enum.HTI":"Haiti",
			"schemas.countries.enum.HMD":"Heardův ostrov a MacDonaldovy ostrovy",
			"schemas.countries.enum.HND":"Honduras",
			"schemas.countries.enum.HKG":"Hongkong",
			"schemas.countries.enum.CHL":"Chile",
			"schemas.countries.enum.HRV":"Chorvatsko",
			"schemas.countries.enum.IND":"Indie",
			"schemas.countries.enum.IDN":"Indonésie",
			"schemas.countries.enum.IRQ":"Irák",
			"schemas.countries.enum.IRN":"Írán",
			"schemas.countries.enum.IRL":"Irsko",
			"schemas.countries.enum.ISL":"Island",
			"schemas.countries.enum.ITA":"Itálie",
			"schemas.countries.enum.ISR":"Izrael",
			"schemas.countries.enum.JAM":"Jamajka",
			"schemas.countries.enum.JPN":"Japonsko",
			"schemas.countries.enum.YEM":"Jemen",
			"schemas.countries.enum.JEY":"Jersey",
			"schemas.countries.enum.ZAF":"Jižní Afrika",
			"schemas.countries.enum.SGS":"Jižní Georgie a Jižní Sandwichovy ostrovy",
			"schemas.countries.enum.SSD":"Jižní Súdán",
			"schemas.countries.enum.JOR":"Jordánsko",
			"schemas.countries.enum.CYM":"Kajmanské ostrovy",
			"schemas.countries.enum.KHM":"Kambodža",
			"schemas.countries.enum.CMR":"Kamerun",
			"schemas.countries.enum.CAN":"Kanada",
			"schemas.countries.enum.CPV":"Kapverdy",
			"schemas.countries.enum.QAT":"Katar",
			"schemas.countries.enum.KAZ":"Kazachstán",
			"schemas.countries.enum.KEN":"Keňa",
			"schemas.countries.enum.KIR":"Kiribati",
			"schemas.countries.enum.CCK":"Kokosové (Keelingovy) ostrovy",
			"schemas.countries.enum.COL":"Kolumbie",
			"schemas.countries.enum.COM":"Komory",
			"schemas.countries.enum.COG":"Konžská republika",
			"schemas.countries.enum.PRK":"Korejská lidově demokratická republika",
			"schemas.countries.enum.KOR":"Korejská republika",
			"schemas.countries.enum.XKO":"Kosovo",
			"schemas.countries.enum.CRI":"Kostarika",
			"schemas.countries.enum.CUB":"Kuba",
			"schemas.countries.enum.KWT":"Kuvajt",
			"schemas.countries.enum.CYP":"Kypr",
			"schemas.countries.enum.KGZ":"Kyrgyzstán",
			"schemas.countries.enum.LAO":"Laos",
			"schemas.countries.enum.LSO":"Lesotho",
			"schemas.countries.enum.LBN":"Libanon",
			"schemas.countries.enum.LBR":"Libérie",
			"schemas.countries.enum.LBY":"Libye",
			"schemas.countries.enum.LIE":"Lichtenštejnsko",
			"schemas.countries.enum.LTU":"Litva",
			"schemas.countries.enum.LVA":"Lotyšsko",
			"schemas.countries.enum.LUX":"Lucembursko",
			"schemas.countries.enum.MAC":"Macao",
			"schemas.countries.enum.MDG":"Madagaskar",
			"schemas.countries.enum.HUN":"Maďarsko",
			"schemas.countries.enum.MKD":"Makedonie",
			"schemas.countries.enum.MYS":"Malajsie",
			"schemas.countries.enum.MWI":"Malawi",
			"schemas.countries.enum.MDV":"Maledivy",
			"schemas.countries.enum.MLI":"Mali",
			"schemas.countries.enum.MLT":"Malta",
			"schemas.countries.enum.IMN":"Man",
			"schemas.countries.enum.MAR":"Maroko",
			"schemas.countries.enum.MHL":"Marshallovy ostrovy",
			"schemas.countries.enum.MTQ":"Martinik",
			"schemas.countries.enum.MUS":"Mauricius",
			"schemas.countries.enum.MRT":"Mauritánie",
			"schemas.countries.enum.MYT":"Mayotte",
			"schemas.countries.enum.UMI":"Menší odlehlé ostrovy USA",
			"schemas.countries.enum.MEX":"Mexiko",
			"schemas.countries.enum.FSM":"Mikronésie",
			"schemas.countries.enum.MDA":"Moldavsko",
			"schemas.countries.enum.MCO":"Monako",
			"schemas.countries.enum.MNG":"Mongolsko",
			"schemas.countries.enum.MSR":"Montserrat",
			"schemas.countries.enum.MOZ":"Mosambik",
			"schemas.countries.enum.MMR":"Myanmar",
			"schemas.countries.enum.NAM":"Namibie",
			"schemas.countries.enum.NRU":"Nauru",
			"schemas.countries.enum.DEU":"Německo",
			"schemas.countries.enum.NPL":"Nepál",
			"schemas.countries.enum.NER":"Niger",
			"schemas.countries.enum.NGA":"Nigérie",
			"schemas.countries.enum.NIC":"Nikaragua",
			"schemas.countries.enum.NIU":"Niue",
			"schemas.countries.enum.NLD":"Nizozemsko",
			"schemas.countries.enum.NFK":"Norfolk",
			"schemas.countries.enum.NOR":"Norsko",
			"schemas.countries.enum.NCL":"Nová Kaledonie",
			"schemas.countries.enum.NZL":"Nový Zéland",
			"schemas.countries.enum.OMN":"Omán",
			"schemas.countries.enum.PAK":"Pákistán",
			"schemas.countries.enum.PLW":"Palau",
			"schemas.countries.enum.PSE":"Palestina",
			"schemas.countries.enum.PAN":"Panama",
			"schemas.countries.enum.PNG":"Papua Nová Guinea",
			"schemas.countries.enum.PRY":"Paraguay",
			"schemas.countries.enum.PER":"Peru",
			"schemas.countries.enum.PCN":"Pitcairn",
			"schemas.countries.enum.CIV":"Pobřeží slonoviny",
			"schemas.countries.enum.POL":"Polsko",
			"schemas.countries.enum.PRI":"Portoriko",
			"schemas.countries.enum.PRT":"Portugalsko",
			"schemas.countries.enum.AUT":"Rakousko",
			"schemas.countries.enum.REU":"Réunion",
			"schemas.countries.enum.GNQ":"Rovníková Guinea",
			"schemas.countries.enum.ROU":"Rumunsko",
			"schemas.countries.enum.RUS":"Rusko",
			"schemas.countries.enum.RWA":"Rwanda",
			"schemas.countries.enum.GRC":"Řecko",
			"schemas.countries.enum.SPM":"Saint Pierre a Miquelon",
			"schemas.countries.enum.SLV":"Salvador",
			"schemas.countries.enum.WSM":"Samoa",
			"schemas.countries.enum.SMR":"San Marino",
			"schemas.countries.enum.SAU":"Saúdská Arábie",
			"schemas.countries.enum.SEN":"Senegal",
			"schemas.countries.enum.MNP":"Severní Mariany",
			"schemas.countries.enum.SYC":"Seychely",
			"schemas.countries.enum.SLE":"Sierra Leone",
			"schemas.countries.enum.SGP":"Singapur",
			"schemas.countries.enum.SVK":"Slovensko",
			"schemas.countries.enum.SVN":"Slovinsko",
			"schemas.countries.enum.SOM":"Somálsko",
			"schemas.countries.enum.ARE":"Spojené arabské emiráty",
			"schemas.countries.enum.USA":"Spojené státy",
			"schemas.countries.enum.SRB":"Srbsko",
			"schemas.countries.enum.CAF":"Středoafrická republika",
			"schemas.countries.enum.SDN":"Súdán",
			"schemas.countries.enum.SUR":"Surinam",
			"schemas.countries.enum.SHN":"Svatá Helena",
			"schemas.countries.enum.LCA":"Svatá Lucie",
			"schemas.countries.enum.BLM":"Svatý Bartoloměj",
			"schemas.countries.enum.KNA":"Svatý Kryštof a Nevis",
			"schemas.countries.enum.MAF":"Svatý Martin (FR)",
			"schemas.countries.enum.SXM":"Svatý Martin (NL)",
			"schemas.countries.enum.STP":"Svatý Tomáš a Princův ostrov",
			"schemas.countries.enum.VCT":"Svatý Vincenc a Grenadiny",
			"schemas.countries.enum.SWZ":"Svazijsko",
			"schemas.countries.enum.SYR":"Sýrie",
			"schemas.countries.enum.SLB":"Šalomounovy ostrovy",
			"schemas.countries.enum.ESP":"Španělsko",
			"schemas.countries.enum.SJM":"Špicberky a Jan Mayen",
			"schemas.countries.enum.LKA":"Šrí Lanka",
			"schemas.countries.enum.SWE":"Švédsko",
			"schemas.countries.enum.CHE":"Švýcarsko",
			"schemas.countries.enum.TJK":"Tádžikistán",
			"schemas.countries.enum.TZA":"Tanzanie",
			"schemas.countries.enum.THA":"Thajsko",
			"schemas.countries.enum.TWN":"Tchaj-wan",
			"schemas.countries.enum.TGO":"Togo",
			"schemas.countries.enum.TKL":"Tokelau",
			"schemas.countries.enum.TON":"Tonga",
			"schemas.countries.enum.TTO":"Trinidad a Tobago",
			"schemas.countries.enum.TUN":"Tunisko",
			"schemas.countries.enum.TUR":"Turecko",
			"schemas.countries.enum.TKM":"Turkmenistán",
			"schemas.countries.enum.TCA":"Turks a Caicos",
			"schemas.countries.enum.TUV":"Tuvalu",
			"schemas.countries.enum.UGA":"Uganda",
			"schemas.countries.enum.UKR":"Ukrajina",
			"schemas.countries.enum.URY":"Uruguay",
			"schemas.countries.enum.UZB":"Uzbekistán",
			"schemas.countries.enum.CXR":"Vánoční ostrov",
			"schemas.countries.enum.VUT":"Vanuatu",
			"schemas.countries.enum.VAT":"Vatikán",
			"schemas.countries.enum.GBR":"Velká Británie",
			"schemas.countries.enum.VEN":"Venezuela",
			"schemas.countries.enum.VNM":"Vietnam",
			"schemas.countries.enum.TLS":"Východní Timor",
			"schemas.countries.enum.WLF":"Wallis a Futuna",
			"schemas.countries.enum.ZMB":"Zambie",
			"schemas.countries.enum.ESH":"Západní Sahara",
			"schemas.countries.enum.ZWE":"Zimbabwe",

			"dashboard.widget.notifications.title": "Notifikace",
			"dashboard.widget.members.openRequests": "Žádosti o registraci",
			"dashboard.widget.data.openRequests":"Žádosti o změnu údajů",
			"dashboard.widget.transfer.openRequests":"Žádosti o transfer",
			"dashboard.widget.transferSolverKM.openRequests":"Žádosti o transfer z klubu",
			"dashboard.widget.members.noOpenRequests": "Žádné žádosti.",
			"dashboard.widget.data.noOpenRequests": "Žádné žádosti.",
			"dashboard.widget.transfer.noOpenRequests": "Žádné žádosti.",

			"competition.part.generated.matches": "Vygenerovány zápasy",

			"portal.edit.title":"Portálový editor",
			"portal.edit.edit":"Editovat",
			"portal.edit.new":"Nový",
			"portal.edit.add.block":"Přidat blok",
			"portal.edit.cancel":"Zrušit",
			"portal.edit.save":"Uložit",
			"portal.edit.new.name":"Název:",
			"portal.edit.new.template":"Template:",
			"portal.edit.new.publish":"Publikovat:",
			"portal.edit.new.publishFrom":"Publikovat od:",
			"portal.edit.new.tags":"Tagy:",
			"portal.edit.block.choice":"Výběr bloku",
			"portal.edit.block.description":"Popis:",
			"portal.menu.title":"Portálové menu",
			"portal.menu.cancel":"Zrušit",
			"portal.menu.send":"Odeslat"
		});

		$translateProvider.translations('en', {
			"forgotten.password.title":"Forgotten Password",
			"forgotten.password.email":"Email",
			"forgotten.password.verify":"Captcha",
			"forgotten.password.email.placeholder":"Registration email",
			"forgotten.password.identification":"Username",
			"forgotten.password.identification.placeholder":"Identifying information",
			"forgotten.password.submit":"Submit",
			"security.forgotten.token.generated":"The link for a password reset has been sent to your email address.",
			"security.forgotten.reset.done":"Your new password has been sent to your email address.",
			"security.forgotten.captcha.validation.failed.incorrect-captcha-sol":"Incorrect captcha.",
			"security.forgotten.token.not.found":"Invalid request. To change the password, refresh the request.",
			"security.forgotten.mail.not.found": "The user with the given username or registration email was not found.",
			"errors.validation.xpsuiArrayUnique": "In the table there are two identical records",
			"errors.validation.xpsuiFormatPattern": "Wrong format",

			"login.title": "Login",
			"login.loginName": "Login name",
			"login.password": "Password",
			"login.forgottenPassword": "Forgotten password",
			"login.authentication.failed": "Authentification failed",
			"login.selected.profile.continue": "Select profile",
			"login.profile": "Profile",
			"enum.active.A": "Active",
			"enum.active.I": "Not active",
			"enum.boolean.true": "Yes",
			"enum.boolean.false": "No",
			"enum.gender.M": "Male",
			"enum.gender.F": "Female",
			"validation.field.not.unique": "Value is not unique",
			"validation.field.not.number": "Value is not a number",
			"validation.field.has.duplicates":"The field contains duplicates.",
			"validation.field.wrong.format":"Value is not in required format.",
			"menu.member.title": "Member",
			"menu.player.title": "Player",
			"menu.fees.title": "Fees",
			"menu.referee.title": "Referee",
			"menu.coach.title": "Coach",
			"menu.stadium.title": "Stadium",
			"menu.club.title": "Club",
			"menu.association.title": "Association",
			"menu.person.title": "Person",
			"menu.transfer.title": "Transfer",
			"menu.season.title": "Season",
			"menu.age.category.title": "Age category",
			"menu.roster.title": "Roster",
			"menu.competition.title": "Competition",
			"menu.company.title": "Organization",
			"menu.my.profile.title": "My profile",
			"menu.permissions.title": "Permissions",
			"menu.schemas.title": "Schemas",
			"menu.new.person.lower.level": "New person",
			"menu.new.company.lower.level": "New organization",
			"menu.search.person.lower.level": "Search person",
			"menu.search.member.lower.level": "Search member",
			"menu.search.player.lower.level": "Search player",
			"menu.search.referee.lower.level": "Search referee",
			"menu.search.coach.lower.level": "Search coach",
			"menu.search.company.lower.level": "Search organization",
			"menu.search.officer.lower.level": "Search representative",
			"menu.search.club.lower.level": "Search club",
			"menu.search.association.lower.level": "Search association",
			"menu.search.statistic.lower.level": "Search statistician",
			"menu.search.medic.lower.level": "Search medic",
			"menu.new.lower.level": "New",
			"menu.new.lower.level.she": "New",
			"menu.search.lower.level": "Search",
			"menu.profile.lower.level": "Profile",
			"menu.dashboard.lower.level": "Dashboard",
			"menu.change.password.lower.level": "Change password",
			"menu.new.group.lower.level": "New group",
			"menu.permission.groups.lower.level": "Permission groups",
			"menu.user.permissions.lower.level": "User permissions",
			"menu.schema.list.lower.level": "Schema list",
			"menu.new.profile.lower.level": "New security profile",
			"menu.profiles.lower.level": "Search security profile",
			"menu.statistics.title": "Statistics",
			"menu.statistics.show.title": "View",
			"menu.massmailing.title": "Mass mail",
			"menu.massmailing.new.lower.level": "New template",
			"menu.massmailing.search.lower.level": "Search",
			"menu.massmailing.send.title": "Send",
			"massmailing.send": "Send",
			"massmailing.sent": "Message sent",
			"massmail.predefined.template": "Predefined templates",
			"massmail.plaintext.template": "Text message",
			"massmail.subject.template": "Subject",
			"massmail.html.template": "Html version",
			"massmail.search.recipient": "Search recipient",
			"massmail.send.view": "Mass mail",
			"massmail.selection.all": "Selection",
			"header.settings": "Settings",
			"header.log.out": "Log out",
			"personal.change.password.change.password": "Password change",
			"personal.change.password.current.password": "Current password",
			"personal.change.password.new.password": "New password",
			"personal.change.password.new.password.check": "New password check",
			"generic.search.searching": "Search",
			"generic.search.add": "Add",
			"generic.search.remove": "Remove",
			"generic.search.removeall": "Remove All",
			"generic.search.camelcase": "Save filter settings",
			"generic.search.removeallfromstorage": "Clear filter settings",
			"generic.search.search": "Search",
			"generic.search.atribute": "Attribute",
			"generic.search.operator": "Operator",
			"generic.search.value": "Value",
			"generic.search.view": "View",
			"generic.search.action": "Action",
			"generic.search.result.of.searching": "Search results",
			"generic.search.export.csv": "Export",
			"generic.search.nodata.found": "No data found",
			"generic.loading": "Loading...",
			"generic.more": "More",
			"generic.file.choose": "Choose a file",
			"generic.file.uploading": "Uploading",
			"generic.noItems.label": "No items.",

			"registry.unsuccesfully.saved":"Could not save data!",
			"registry.succesfully.saved":"Successfully saved",
			"registry.new.new": "New",
			"registry.new.cancel": "Cancel",
			"registry.new.send": "Send",
			"registry.send": "Send",
			"registry.cancel": "Cancel",
			"registry.successfully.generated": "The content of the page successfully generated",
			"security.group.edit.list.of.security.groups": "List of security groups",
			"security.group.edit.id": "ID",
			"security.group.edit.edit": "Edit",
			"security.group.edit.actions": "Actions",
			"security.group.edit.save": "Save",
			"security.group.edit.available.permissions": "Available permissions",
			"security.group.edit.name": "Name",
			"security.group.edit.added.permissions": "Added Permissions",
			"security.group.edit.permissions": "Permissions",
			"security.group.edit.name.of.group": "Group name",
			"security.group.edit.id.of.group": "Group id",
			"security.group.edit.modification.of.security.group": "Security group modification",
			"security.user.edit.modification.done": "User modified",
			"security.user.missing.permissions": "Missing permissions",
			"security.user.session.expired": "Session expired",
			"security.profile.edit.searching": "Search security profile",
			"security.profile.edit.entity": "Security profile",
			"security.profile.edit.add": "Add",
			"security.profile.edit.search": "Search",
			"security.profile.edit.result": "Result",
			"security.profile.edit.actions": "Actions",
			"security.profile.edit.name.data": "Data",
			"security.profile.edit.name": "Name",
			"security.profile.edit.groups": "Groups",
			"security.profile.edit.added.groups": "Added groups",
			"security.profile.edit.available.groups": "Available groups",
			"security.profile.edit.permissions": "Permissions",
			"security.profile.edit.added.permissions": "Added permissions",
			"security.profile.edit.available.permissions": "Available permissions",
			"security.profile.edit.save": "Save",
			"security.profile.edit.cancel": "Cancel",
			"security.profile.edit.edit": "Edit",
			"security.profile.edit.data.criteria": "Data filter",
			"security.user.edit.entity": "User",
			"security.user.edit.profile": "User profile",
			"security.user.edit.added.profiles": "Added profiles",
			"security.user.edit.available.profiles": "Available profiles",
			"security.profile.edit.modification.done": "Successfully modified",
			"schema.editor.save": "Save",
			"schema.editor.cancel": "Cancel",
			"schema.editor.edit": "Edit",
			"schema.editor.id": "Id",
			"schema.editor.name": "Name",
			"schema.editor.actions": "Actions",
			"schema.editor.size": "Size",
			"schema.editor.list.of.schemas.for.editation": "List of schemas for editing",
			"schema.org.title": "New organization",
			"schema.stadium.title": "New stadium",
			"personal.change.password.passwords.not.equal": "Passwords are not identical",
			"personal.change.password.password.changed": "Password changed",
			"personal.change.password.password.not.changed": "Password not changed",
			"server.side.exception":"Server side exception",

			"queryfilter.ops.EXISTS": "exists",
			"queryfilter.ops.NOT_EXISTS": "not exists",
			"queryfilter.ops.EQUAL": "equal to",
			"queryfilter.ops.GREATER": "greater than (>)",
			"queryfilter.ops.GREATER_EQUAL": "greater than or equal to (>=)",
			"queryfilter.ops.LESS": "less than (<)",
			"queryfilter.ops.LESS_EQUAL": "less than or equal to (<=)",
			"queryfilter.ops.NOT_IN": "not in",
			"queryfilter.ops.IN": "in",
			"queryfilter.ops.NOT_EQUAL": "not equal",
			"queryfilter.ops.CONTAINS": "contains",
			"queryfilter.ops.STARTS_WITH": "starts with",
			"queryfilter.ops.CONTAINS_NORMALIZED": "contains (without diacritic)",
			"queryfilter.ops.STARTS_WITH_NORMALIZED": "starts with (without diacritic)",
			"queryfilter.ops.OR": "or",
			"queryfilter.ops.AND": "and",
			"queryfilter.ops.ALL": "all",
			"queryfilter.ops.REGEX": "regular expression",


			"registry.successfully.saved": "Succesfully saved",
			"registry.unsuccessfully.saved": "Unsuccessfully saved",
			"registry.form.not.filled.correctly": "Form not filled correctly",
			"psui.uploadable.image.unsupported.image.type": "Unsupported image type",
			"psui.imageresizor.rotate": "Rotate",
			"psui.imageresizor.ok": "Ok",
			"psui.uploadable.file.unsupported.file.type": "Unsupported file type",
			"psui.selectbox.loading":"Loading...",
			"psui.selectbox.rows":"Rows",
			"date.monday": "Mo",
			"date.tuesday": "Tu",
			"date.wednesday": "We",
			"date.thursday": "Th",
			"date.friday": "Fr",
			"date.saturday": "Sa",
			"date.sunday": "Su",
			"date.jan": "Jan",
			"date.feb": "Feb",
			"date.mar": "Mar",
			"date.apr": "Apr",
			"date.may": "May",
			"date.jun": "Jun",
			"date.jul": "Jul",
			"date.aug": "Aug",
			"date.sep": "Sep",
			"date.oct": "Oct",
			"date.nov": "Nov",
			"date.dec": "Dec",
			"date.current.day": "Current day",
			"security.user.edit.searching": "Search",
			"security.user.edit.value": "Value",
			"security.user.edit.operator": "Operator",
			"security.user.edit.save": "Save",
			"security.user.edit.available.permissions": "Available permissions",
			"security.user.edit.added.permissions": "Added permissions",
			"security.user.edit.permissions.for.user": "User permissions",
			"security.user.edit.available.groups": "Available groups",
			"security.user.edit.added.groups": "Added groups",
			"security.user.edit.groups.for.users": "User groups",
			"security.user.edit.edit": "Edit",
			"security.user.edit.actions": "Actions",
			"security.user.edit.result": "Result",
			"security.user.edit.search": "Search",
			"security.user.edit.add": "Add",
			"security.user.edit.attribute": "Attribute",
			"security.user.edit.cancel": "Cancel",
			"security.user.edit.reset.password": "Reset password",
			"security.user.edit.login.data": "Login data",
			"security.user.edit.login": "Login data",
			"security.user.edit.email": "E-mail",
			"psui-objectlink.btn.save": "Save",
			"psui-objectlink.btn.cancel": "Cancel",
			"errors.validation.required": "Required field",
			"errors.validation.objLinkRequired": "Required field",
			"errors.validation.psuiUnique": "Value exists in system",
			"errors.validation.parse":"Incorrect format",
			"errors.validation.valid":"Invalid format",
			"schema.editor.schema.editing": "Schema editor",
			"security.group.edit.parent.group": "Parent group",
			"schemas.group.base.data": "Base data",
			"schemas.group.base.data.ID": "Id",
			"schemas.group.base.data.name": "Group name",
			"schemas.group.base.data.parent.group": "Parent group",
			"schema.statistics.view": "Statistics",
			"schema.statistics.baseData": "Basic",
			"schema.statistics.member.count": "Number of members",
			"schema.statistics.women.count": "Number of women",
			"schema.statistics.fees": "Fees",
			"schema.statistics.fees.expected.count": "Number of fees expected",
			"schema.statistics.fees.expected.sum": "Value of fees expected",
			"schema.statistics.fees.paid.count": "Number of fees paid",
			"schema.statistics.fees.paid.sum": "Value of fees paid",
			"schema.statistics.fees.overdue.count": "Number of fees overdue",
			"schema.statistics.fees.overdue.sum": "Value of fees overdue",
			"schemas.countries.enum.AFG": "Afghanistan",
			"schemas.countries.enum.ALA": "Aland",
			"schemas.countries.enum.ALB": "Albania",
			"schemas.countries.enum.DZA": "Algeria",
			"schemas.countries.enum.ASM": "American Samoa",
			"schemas.countries.enum.VIR": "United States Virgin Islands",
			"schemas.countries.enum.AND": "Andorra",
			"schemas.countries.enum.AGO": "Angola",
			"schemas.countries.enum.AIA": "Anguilla",
			"schemas.countries.enum.ATA": "Antarctica",
			"schemas.countries.enum.ATG": "Antigua and Barbuda",
			"schemas.countries.enum.ARG": "Argentina",
			"schemas.countries.enum.ARM": "Armenia",
			"schemas.countries.enum.ABW": "Aruba",
			"schemas.countries.enum.AUS": "Australia",
			"schemas.countries.enum.AZE": "Azerbaijan",
			"schemas.countries.enum.BHS": "Bahamas",
			"schemas.countries.enum.BHR": "Bahrain",
			"schemas.countries.enum.BGD": "Bangladesh",
			"schemas.countries.enum.BRB": "Barbados",
			"schemas.countries.enum.BEL": "Belgium",
			"schemas.countries.enum.BLZ": "Belize",
			"schemas.countries.enum.BLR": "Belarus",
			"schemas.countries.enum.BEN": "Benin",
			"schemas.countries.enum.BMU": "Bermuda",
			"schemas.countries.enum.BTN": "Bhutan",
			"schemas.countries.enum.BOL": "Bolivia",
			"schemas.countries.enum.BES": "Bonaire",
			"schemas.countries.enum.BIH": "Bosnia and Hercegovina",
			"schemas.countries.enum.BWA": "Botswana",
			"schemas.countries.enum.BVT": "Bouvetoya ",
			"schemas.countries.enum.BRA": "Brazil",
			"schemas.countries.enum.IOT": "British Indian Ocean Territory",
			"schemas.countries.enum.VGB": "British Virgin Islands",
			"schemas.countries.enum.BRN": "Brunei Darussalm",
			"schemas.countries.enum.BGR": "Bulgaria",
			"schemas.countries.enum.BFA": "Burkina Faso",
			"schemas.countries.enum.BDI": "Burundi",
			"schemas.countries.enum.COK": "Cook Islands",
			"schemas.countries.enum.CUW": "Curacao",
			"schemas.countries.enum.TCD": "Chad",
			"schemas.countries.enum.MNE": "Montenegro",
			"schemas.countries.enum.CZE": "Czech Republic",
			"schemas.countries.enum.CHN": "China",
			"schemas.countries.enum.DNK": "Denmark",
			"schemas.countries.enum.COD": "Congo",
			"schemas.countries.enum.DMA": "Dominica",
			"schemas.countries.enum.DOM": "Dominican Republic",
			"schemas.countries.enum.DJI": "Djibouti",
			"schemas.countries.enum.EGY": "Egypt",
			"schemas.countries.enum.ECU": "Ecuador",
			"schemas.countries.enum.ERI": "Eritrea",
			"schemas.countries.enum.EST": "Estonia",
			"schemas.countries.enum.ETH": "Ethiopia",
			"schemas.countries.enum.FRO": "Faroe Islands",
			"schemas.countries.enum.FLK": "Falkland Islands",
			"schemas.countries.enum.FJI": "Fiji",
			"schemas.countries.enum.PHL": "Philippines",
			"schemas.countries.enum.FIN": "Finland",
			"schemas.countries.enum.FRA": "France",
			"schemas.countries.enum.GUF": "French Guiana",
			"schemas.countries.enum.ATF": "French Southern and Antarctic Lands",
			"schemas.countries.enum.PYF": "French Polynesia",
			"schemas.countries.enum.GAB": "Gabon",
			"schemas.countries.enum.GMB": "Gambia",
			"schemas.countries.enum.GHA": "Ghana",
			"schemas.countries.enum.GIB": "Gibraltar",
			"schemas.countries.enum.GRD": "Grenada",
			"schemas.countries.enum.GRL": "Greenland",
			"schemas.countries.enum.GEO": "Georgia",
			"schemas.countries.enum.GLP": "Guadeloupe",
			"schemas.countries.enum.GUM": "Guam",
			"schemas.countries.enum.GTM": "Guatemala",
			"schemas.countries.enum.GGY": "Guernsey",
			"schemas.countries.enum.GIN": "Guinea",
			"schemas.countries.enum.GNB": "Guinea-Bissau",
			"schemas.countries.enum.GUY": "Guyana",
			"schemas.countries.enum.HTI": "Haiti",
			"schemas.countries.enum.HMD": "Heard Island",
			"schemas.countries.enum.HND": "Honduras",
			"schemas.countries.enum.HKG": "Hong Kong",
			"schemas.countries.enum.CHL": "Chile",
			"schemas.countries.enum.HRV": "Croatia",
			"schemas.countries.enum.IND": "India",
			"schemas.countries.enum.IDN": "Indonesia",
			"schemas.countries.enum.IRQ": "Iraq",
			"schemas.countries.enum.IRN": "Iran",
			"schemas.countries.enum.IRL": "Ireland",
			"schemas.countries.enum.ISL": "Iceland",
			"schemas.countries.enum.ITA": "Italy",
			"schemas.countries.enum.ISR": "Israel",
			"schemas.countries.enum.JAM": "Jamaica",
			"schemas.countries.enum.JPN": "Japan",
			"schemas.countries.enum.YEM": "Yemen",
			"schemas.countries.enum.JEY": "Jersey",
			"schemas.countries.enum.ZAF": "South Africa",
			"schemas.countries.enum.SGS": "South Georgia and the South Sandwich Islands",
			"schemas.countries.enum.SSD": "South Sudan",
			"schemas.countries.enum.JOR": "Jordan",
			"schemas.countries.enum.CYM": "Cayman Islands",
			"schemas.countries.enum.KHM": "Cambodia",
			"schemas.countries.enum.CMR": "Cameroon",
			"schemas.countries.enum.CAN": "Canada",
			"schemas.countries.enum.CPV": "Cabo Verde",
			"schemas.countries.enum.QAT": "Qatar",
			"schemas.countries.enum.KAZ": "Kazakhstan",
			"schemas.countries.enum.KEN": "Kenya",
			"schemas.countries.enum.KIR": "Kiribati",
			"schemas.countries.enum.CCK": "Cocos Islands",
			"schemas.countries.enum.COL": "Colombia",
			"schemas.countries.enum.COM": "Comoros",
			"schemas.countries.enum.COG": "Congo ",
			"schemas.countries.enum.PRK": "North Korea",
			"schemas.countries.enum.KOR": "South Korea",
			"schemas.countries.enum.XKO": "Kosovo",
			"schemas.countries.enum.CRI": "Costa Rica",
			"schemas.countries.enum.CUB": "Cuba",
			"schemas.countries.enum.KWT": "Kuwait",
			"schemas.countries.enum.CYP": "Cyprus",
			"schemas.countries.enum.KGZ": "Kyrgyz Republic",
			"schemas.countries.enum.LAO": "Laos",
			"schemas.countries.enum.LSO": "Lesotho",
			"schemas.countries.enum.LBN": "Lebanon",
			"schemas.countries.enum.LBR": "Liberia",
			"schemas.countries.enum.LBY": "Libya",
			"schemas.countries.enum.LIE": "Liechtenstein",
			"schemas.countries.enum.LTU": "Lithuania",
			"schemas.countries.enum.LVA": "Latvia",
			"schemas.countries.enum.LUX": "Luxembourg",
			"schemas.countries.enum.MAC": "Macao",
			"schemas.countries.enum.MDG": "Madagascar",
			"schemas.countries.enum.HUN": "Hungary",
			"schemas.countries.enum.MKD": "Macedonia",
			"schemas.countries.enum.MYS": "Malaysia",
			"schemas.countries.enum.MWI": "Malawi",
			"schemas.countries.enum.MDV": "Maldives",
			"schemas.countries.enum.MLI": "Mali",
			"schemas.countries.enum.MLT": "Malta",
			"schemas.countries.enum.IMN": "Isle of Man",
			"schemas.countries.enum.MAR": "Morocco",
			"schemas.countries.enum.MHL": "Marshall Islands",
			"schemas.countries.enum.MTQ": "Martinique",
			"schemas.countries.enum.MUS": "Mauritius",
			"schemas.countries.enum.MRT": "Mauritania",
			"schemas.countries.enum.MYT": "Mayotte",
			"schemas.countries.enum.UMI": "United States Minor Outlying Islands",
			"schemas.countries.enum.MEX": "Mexico",
			"schemas.countries.enum.FSM": "Micronesia",
			"schemas.countries.enum.MDA": "Moldova",
			"schemas.countries.enum.MCO": "Monaco",
			"schemas.countries.enum.MNG": "Mongolia",
			"schemas.countries.enum.MSR": "Montserrat",
			"schemas.countries.enum.MOZ": "Mozambique",
			"schemas.countries.enum.MMR": "Myanmar",
			"schemas.countries.enum.NAM": "Namibia",
			"schemas.countries.enum.NRU": "Nauru",
			"schemas.countries.enum.DEU": "Germany",
			"schemas.countries.enum.NPL": "Nepal",
			"schemas.countries.enum.NER": "Niger",
			"schemas.countries.enum.NGA": "Nigeria",
			"schemas.countries.enum.NIC": "Nicaragua",
			"schemas.countries.enum.NIU": "Niue Island",
			"schemas.countries.enum.NLD": "Netherlands",
			"schemas.countries.enum.NFK": "Norfolk Island",
			"schemas.countries.enum.NOR": "Norway",
			"schemas.countries.enum.NCL": "New Caledonia",
			"schemas.countries.enum.NZL": "New Zealand",
			"schemas.countries.enum.OMN": "Oman",
			"schemas.countries.enum.PAK": "Pakistan",
			"schemas.countries.enum.PLW": "Palau",
			"schemas.countries.enum.PSE": "Palestine",
			"schemas.countries.enum.PAN": "Panama",
			"schemas.countries.enum.PNG": "Papua New Guinea",
			"schemas.countries.enum.PRY": "Paraguay",
			"schemas.countries.enum.PER": "Peru",
			"schemas.countries.enum.PCN": "Pitcairn",
			"schemas.countries.enum.CIV": "Ivory Coast ",
			"schemas.countries.enum.POL": "Poland",
			"schemas.countries.enum.PRI": "Puerto Rico",
			"schemas.countries.enum.PRT": "Portugal",
			"schemas.countries.enum.AUT": "Austria",
			"schemas.countries.enum.REU": "Reunion",
			"schemas.countries.enum.GNQ": "Guinea Ecuatorial",
			"schemas.countries.enum.ROU": "Romania",
			"schemas.countries.enum.RUS": "Russia",
			"schemas.countries.enum.RWA": "Rwanda",
			"schemas.countries.enum.GRC": "Greece",
			"schemas.countries.enum.SPM": "St Pierre and Miquelon",
			"schemas.countries.enum.SLV": "El Salvador",
			"schemas.countries.enum.WSM": "Samoa",
			"schemas.countries.enum.SMR": "San Marino",
			"schemas.countries.enum.SAU": "Saudi Arabia",
			"schemas.countries.enum.SEN": "Senegal",
			"schemas.countries.enum.MNP": "Northern Mariana Islands",
			"schemas.countries.enum.SYC": "Seychelles",
			"schemas.countries.enum.SLE": "Sierra Leone",
			"schemas.countries.enum.SGP": "Singapore",
			"schemas.countries.enum.SVK": "Slovakia",
			"schemas.countries.enum.SVN": "Slovenia",
			"schemas.countries.enum.SOM": "Somalia",
			"schemas.countries.enum.ARE": "United Arab Emirates",
			"schemas.countries.enum.USA": "United States",
			"schemas.countries.enum.SRB": "Serbia",
			"schemas.countries.enum.CAF": "Central Africa",
			"schemas.countries.enum.SDN": "Sudan",
			"schemas.countries.enum.SUR": "Suriname",
			"schemas.countries.enum.SHN": "St Helena and Tristan da Cunha",
			"schemas.countries.enum.LCA": "St Lucia",
			"schemas.countries.enum.BLM": "Saint-Barthelemy",
			"schemas.countries.enum.KNA": "St Kitts and Nevis",
			"schemas.countries.enum.MAF": "Saint-Martin",
			"schemas.countries.enum.SXM": "Sint Maarten",
			"schemas.countries.enum.STP": "Sao Tome & Principe",
			"schemas.countries.enum.VCT": "St Vincent/Grenadines",
			"schemas.countries.enum.SWZ": "Swaziland",
			"schemas.countries.enum.SYR": "Syria",
			"schemas.countries.enum.SLB": "Solomon Islands",
			"schemas.countries.enum.ESP": "Spain",
			"schemas.countries.enum.SJM": "Svalbard",
			"schemas.countries.enum.LKA": "Sri Lanka",
			"schemas.countries.enum.SWE": "Sweden",
			"schemas.countries.enum.CHE": "Switzerland",
			"schemas.countries.enum.TJK": "Tajikistan",
			"schemas.countries.enum.TZA": "Tanzania",
			"schemas.countries.enum.THA": "Thailand",
			"schemas.countries.enum.TWN": "Taiwan",
			"schemas.countries.enum.TGO": "Togolese Republic",
			"schemas.countries.enum.TKL": "Tokelau",
			"schemas.countries.enum.TON": "Tonga",
			"schemas.countries.enum.TTO": "Trinidad and Tobago",
			"schemas.countries.enum.TUN": "Tunisia",
			"schemas.countries.enum.TUR": "Turkey",
			"schemas.countries.enum.TKM": "Turkmenistan",
			"schemas.countries.enum.TCA": "Turks and Caicos",
			"schemas.countries.enum.TUV": "Tuvalu",
			"schemas.countries.enum.UGA": "Uganda",
			"schemas.countries.enum.UKR": "Ukraine",
			"schemas.countries.enum.URY": "Uruguay",
			"schemas.countries.enum.UZB": "Uzbekistan",
			"schemas.countries.enum.CXR": "Christmas Island",
			"schemas.countries.enum.VUT": "Vanuatu",
			"schemas.countries.enum.VAT": "Vatican City",
			"schemas.countries.enum.GBR": "Great Britain",
			"schemas.countries.enum.VEN": "Venezuela",
			"schemas.countries.enum.VNM": "Vietnam",
			"schemas.countries.enum.TLS": "East Timor",
			"schemas.countries.enum.WLF": "Wallis & Futuna",
			"schemas.countries.enum.ZMB": "Zambia",
			"schemas.countries.enum.ESH": "Western Sahara",
			"schemas.countries.enum.ZWE": "Zimbabwe",

			"dashboard.widget.notifications.title": "Notifications",
			"dashboard.widget.members.openRequests": "Registration requests",
			"dashboard.widget.data.openRequests":"Data change requests",
			"dashboard.widget.transfer.openRequests":"Transfer requests",
			"dashboard.widget.transferSolverKM.openRequests":"Transfer requests from the club",
			"dashboard.widget.members.noOpenRequests": "No requests.",
			"dashboard.widget.data.noOpenRequests": "No requests.",
			"dashboard.widget.transfer.noOpenRequests": "No requests.",

			"competition.part.generated.matches": "Generated matches",

			"portal.edit.title":"Portal editor",
			"portal.edit.edit":"Edit",
			"portal.edit.new":"New",
			"portal.edit.add.block":"Add a block",
			"portal.edit.cancel":"Cancel",
			"portal.edit.save":"Save",
			"portal.edit.new.name":"Name:",
			"portal.edit.new.template":"Template:",
			"portal.edit.new.publish":"Publish:",
			"portal.edit.new.publishFrom":"Publish from:",
			"portal.edit.new.tags":"Tags:",
			"portal.edit.block.choice":"Block choice",
			"portal.edit.block.description":"Description:",
			"portal.menu.title":"Portal menu",
			"portal.menu.cancel":"Cancel",
			"portal.menu.send":"Send"
		});

$translateProvider.translations('ja', {
			"login.title": "ログイン画面",
			"login.loginName": "ログイン名",
			"login.password": "パスワード",
			"login.forgottenPassword": "パスワードを忘れた場合",
			"login.authentication.failed": "認証に失敗",
			"login.selected.profile.continue":"プロフィールを選択して続行",
			"login.profile":"Profil",
			"label.date": "Dátum",

			"enum.active.A":"Aktívny",
			"enum.active.I":"Neaktívny",

			"enum.boolean.true":"Áno",
			"enum.boolean.false":"Nie",

			"enum.gender.M":"Muž",
			"enum.gender.F":"Žena",

			"validation.field.not.unique":"Hodnota nie je unikátna.",
			"validation.field.not.number":"Hodnota nie je číslo.",
			"validation.field.has.duplicates":"Pole obsahuje duplicity.",
			"validation.field.wrong.format":"Hodnota nie je v požadovanom formáte.",

			"forgotten.password.title":"Zabudnuté heslo",
			"forgotten.password.email":"Email",
			"forgotten.password.verify":"Kontrola",
			"forgotten.password.email.placeholder":"Registračný email",
			"forgotten.password.identification":"Login",
			"forgotten.password.identification.placeholder":"Identifikačný údaj",
			"forgotten.password.submit":"Odoslať",
			"security.forgotten.token.generated":"Linka pre resetnutie hesla bola zaslaná na Vašu mailovú adresu.",
			"security.forgotten.reset.done":"Nové heslo bolo zaslané na Vašu mailovú adresu.",
			"security.forgotten.captcha.validation.failed.incorrect-captcha-sol":"Captcha nesprávne zadaná.",
			"security.forgotten.token.not.found":"Neplatná požiadavka. Pre zmenu hesla aktualizujte požiadavku.",
			"security.forgotten.mail.not.found": "Uživateľ s daným mailom alebo loginom nebol najdený.",

			"menu.member.title": "Člen",
			"menu.player.title": "Hráč",
			"menu.fees.title": "Poplatok",
			"menu.referee.title": "Rozhodca",
			"menu.coach.title": "Tréner",
			"menu.stadium.title": "Štadión",
			"menu.club.title": "Klub",
			"menu.association.title": "Zväz",
			"menu.person.title": "Osoba",
			"menu.transfer.title": "Zmena kl. príslušnosti",
			"menu.season.title": "Súťažný ročník",
			"menu.age.category.title": "Veková kategória",
			"menu.roster.title": "Súpisky",
			"menu.competition.title": "Súťaž",
			"menu.company.title": "Organizácia",
			"menu.my.profile.title": "Môj profil",
			"menu.permissions.title": "Oprávnenia",
			"menu.schemas.title": "Schémy",

			"menu.new.person.lower.level": "Nová osoba",
			"menu.new.company.lower.level": "Nová spoločnosť",

			"menu.search.person.lower.level": "Hľadať osobu",
			"menu.search.member.lower.level": "Hľadať člena",
			"menu.search.player.lower.level": "Hľadať hráča",
			"menu.search.referee.lower.level": "Hľadať rozhodcu",
			"menu.search.coach.lower.level": "Hľadať trénera",
			"menu.search.company.lower.level": "Hľadať spoločnosť",
			"menu.search.officer.lower.level": "Hľadať funkcionára",
			"menu.search.club.lower.level": "Hľadať klub",
			"menu.search.association.lower.level": "Hľadať zväz",
			"menu.search.statistic.lower.level": "Hľadať štatistika",
			"menu.search.medic.lower.level": "Hľadať zdravotníka",

			"menu.new.lower.level": "Nový",
			"menu.new.lower.level.she": "Nová",
			"menu.search.lower.level": "Hľadať",
			"menu.profile.lower.level": "Profil",
			"menu.dashboard.lower.level": "Dashboard",
			"menu.change.password.lower.level": "Zmena hesla",
			"menu.new.group.lower.level": "Nová skupina",
			"menu.permission.groups.lower.level": "Skupiny oprávnení",
			"menu.user.permissions.lower.level": "Oprávnenia používateľov",
			"menu.schema.list.lower.level": "Zoznam schém",
			"menu.new.profile.lower.level":"Nový bezpečnostný profil",
			"menu.profiles.lower.level":"Hľadať bezpečnostný profil",
			"menu.statistics.title":"Štatistiky",
			"menu.statistics.show.title":"Zobraziť",

			"menu.massmailing.title":"Hromadná pošta",
			"menu.massmailing.new.lower.level":"Nový vzor",
			"menu.massmailing.search.lower.level":"Vyhľadáť",
			"menu.massmailing.send.title":"Odoslať",
			"massmailing.send":"Odoslať",
			"massmailing.sent":"Správa bola odoslaná.",

			"massmail.predefined.template": "Preddefinované vzory",
			"massmail.plaintext.template":"Text správy",
			"massmail.subject.template":"Predment správy",
			"massmail.html.template":"Html verzia správy",
			"massmail.search.recipient":"Vyhľadáť príjemcov",
			"massmail.send.view":"Hromadná pošta",
			"massmail.selection.all":"Podľa filtra",

			"header.settings": "Nastavenia",
			"header.log.out": "Odhlásiť sa",
			"personal.change.password.change.password": "Zmena hesla",
			"personal.change.password.current.password": "Staré heslo",
			"personal.change.password.new.password": "Nové heslo",
			"personal.change.password.new.password.check": "Kontrola nového hesla",
//FIXME remove generic search
			"generic.search.searching": "Vyhľadávanie",
			"generic.search.add": "Pridať",
			"generic.search.remove": "Zrušiť",
			"generic.search.search": "Hľadať",
			"generic.search.atribute": "Atribút",
			"generic.search.operator": "Operátor",
			"generic.search.value": "Hodnota",
			"generic.search.view": "Zobraziť",
			"generic.search.action": "Akcia",
			"generic.search.result.of.searching": "Výsledok vyhľadávania",
			"generic.search.export.csv": "Exportovať",
			"generic.search.nodata.found":"Neboli najdené žiadne dáta.",
			"generic.noItems.label": "No Items.",
			"generic.file.choose": "Zvoliť súbor",
			"generic.file.uploadFailed": "Uloženie súboru zlyhalo.",
			"generic.file.uploading": "Nahráva sa",
			"generic.loading": "Načítava...",
			"generic.more": "Viac",
//FIXME translate into other languages
			"queryfilter.ops.EXISTS": "existuje (má hodnotu)",
			"queryfilter.ops.NOT_EXISTS": "neexistuje (nemá hodnotu)",
			"queryfilter.ops.EQUAL": "je rovné (=)",
			"queryfilter.ops.GREATER": "je väčšie (>)",
			"queryfilter.ops.GREATER_EQUAL": "je väčšie alebo rovné (>=)",
			"queryfilter.ops.LESS": "je menšie (<)",
			"queryfilter.ops.LESS_EQUAL": "je menšie alebo rovné (<=)",
			"queryfilter.ops.NOT_EQUAL": "je iné ako",
			"queryfilter.ops.CONTAINS": "obsahuje (s diakritikou)",
			"queryfilter.ops.STARTS_WITH": "začína s (s diakritikou)",
			"queryfilter.ops.CONTAINS_NORMALIZED": "obsahuje (bez diakritiky)",
			"queryfilter.ops.STARTS_WITH_NORMALIZED": "začína s (bez diakritiky)",
			"queryfilter.ops.OR": "alebo",
			"queryfilter.ops.AND": "a",
			"queryfilter.ops.NOT_IN": "neobsahuje žiadny zo záznamov tabulky",
			"queryfilter.ops.IN": "obsahuje aspoň jeden záznam z tabuľky",
			"queryfilter.ops.ALL": "obsahuje všetky záznamy z tabuľky",
			"queryfilter.ops.REGEX": "regulárny výraz",
			"registry.new.new": "Nový",
			"registry.new.cancel": "Zrušiť",
			"registry.new.send": "Odoslať",
			"registry.successfully.generated":"Obsah stránky úspešne vypočítaný",
			"security.group.edit.list.of.security.groups": "Zoznam bezpečnostných skupín",
			"security.group.edit.id": "ID",
			"security.group.edit.edit": "Upraviť",
			"security.group.edit.actions": "Akcie",
			"security.group.edit.save": "Uložiť",
			"security.group.edit.available.permissions": "Dostupné práva",
			"security.group.edit.name": "Názov",
			"security.group.edit.added.permissions": "Pridelené práva",
			"security.group.edit.permissions": "Oprávnenia",
			"security.group.edit.name.of.group": "Názov skupiny",
			"security.group.edit.id.of.group": "Identifikátor skupiny",
			"security.group.edit.modification.of.security.group": "Modifikácia bezpečnostnej skupiny",
			"security.user.edit.modification.done": "Použivateľ bol modifikovaný",
			"security.user.missing.permissions":"Chybajúce oprávnenie: {{data}}",
			"security.user.session.expired":"Pre nečinnosť ste boli odhlásený.",
			"security.current.password.does.not.match": "Heslo sa nepodarilo zmeniť. Staré heslo nie je správne.",
			"security.login.does.not.match": "Login is not filled in.",

			"security.profile.edit.searching":"Bezpečnostný profil vyhľadávanie",
			"security.profile.edit.add":"Pridať",
			"security.profile.edit.search":"Vyhľadávať",
			"security.profile.edit.result":"Výsledok",
			"security.profile.edit.actions":"Akcie",
			"security.profile.edit.name.data":"data",
			"security.profile.edit.name":"Názov",
			"security.profile.edit.groups":"Skupiny",
			"security.profile.edit.added.groups":"Pridelené skupiny",
			"security.profile.edit.available.groups":"Dostupné skupiny",
			"security.profile.edit.permissions":"Oprávnenia ",
			"security.profile.edit.added.permissions":"Pridelené oprávnenia",
			"security.profile.edit.available.permissions":"Dostupné oprávnenia",
			"security.profile.edit.save":"Uložiť",
			"security.profile.edit.cancel":"Zrušiť",
			"security.profile.edit.edit":"Editovať",
			"security.profile.edit.data.criteria":"Filtrácia dát",
			"security.user.edit.profile":"Profily používateľa",
			"security.user.edit.added.profiles":"Pridelené profily",
			"security.user.edit.available.profiles":"Dostupné profily",
			"security.profile.edit.modification.done":"Úspešne uložené",
			"server.side.exception":"Výnimka na strane servera",

			"schema.editor.save": "Uložiť",
			"schema.editor.cancel": "Zrušiť",
			"schema.editor.edit": "Upraviť",
			"schema.editor.id": "ID",
			"schema.editor.name": "Názov",
			"schema.editor.actions": "Akcie",
			"schema.editor.size": "Veľkosť",
			"schema.editor.list.of.schemas.for.editation": "Zoznam schém na editáciu",
			"schema.org.title": "Nová organizácia",
			"schema.stadium.title": "Nový štadión",
			"personal.change.password.passwords.not.equal": "Nové a kontrolné heslo sa nerovnajú!",
			"personal.change.password.password.changed": "Heslo zmenené",
			"personal.change.password.password.not.changed": "Heslo sa nepodarilo zmeniť: {{data}}",
			"registry.successfully.saved":"Úspešne uložené",
			"registry.unsuccessfully.saved":"Nepodarilo sa uložiť dáta!",
			"registry.form.not.filled.correctly":"Formulár nie je správne vyplnený",
			"psui.uploadable.image.unsupported.image.type":"Nepodporovaný formát obrázku",
			"psui.imageresizor.rotate":"otočiť",
			"psui.imageresizor.ok":"ok",
			"psui.uploadable.file.unsupported.file.type":"Nepodporovaný formát súboru",
			"psui.selectbox.loading":"Načítava...",
			"psui.selectbox.rows":"Počet riadkov",
			"date.monday":"Po",
			"date.tuesday":"Ut",
			"date.wednesday":"St",
			"date.thursday":"Št",
			"date.friday":"Pi",
			"date.saturday":"So",
			"date.sunday":"Ne",
			"date.jan":"Jan",
			"date.feb":"Feb",
			"date.mar":"Mar",
			"date.apr":"Apr",
			"date.may":"Máj",
			"date.jun":"Jún",
			"date.jul":"Júl",
			"date.aug":"Aug",
			"date.sep":"Sep",
			"date.oct":"Okt",
			"date.nov":"Nov",
			"date.dec":"Dec",
			"date.current.day":"Dnešný deň",
			"security.user.edit.searching":"Vyhľadávanie",
			"security.user.edit.value":"Hodnota",
			"security.user.edit.operator":"Operátor",
			"security.user.edit.save":"Uložiť",
			"security.user.edit.available.permissions":"Dostupné práva",
			"security.user.edit.added.permissions":"Pridelené práva",
			"security.user.edit.permissions.for.user":"Práva pre používateľa",
			"security.user.edit.available.groups":"Dostupné skupiny",
			"security.user.edit.added.groups":"Pridelené skupiny",
			"security.user.edit.groups.for.users":"Skupiny pre používateľa",
			"security.user.edit.edit":"Upraviť",
			"security.user.edit.actions":"Akcie",
			"security.user.edit.result":"Výsledok",
			"security.user.edit.search":"Hľadať",
			"security.user.edit.add":"Pridať",
			"security.user.edit.attribute":"Atribút",
			"security.user.edit.cancel":"Zrušiť",
			"security.user.edit.reset.password":"Resetovať heslo",
			"security.user.edit.login.data":"Prihlasovacie údaje",
			"security.user.edit.login":"Login",
			"security.user.edit.email":"E-mail",
			"psui-objectlink.btn.save": "Uložiť",
			"psui-objectlink.btn.cancel": "Zrušiť",

			"errors.validation.required": "Povinné pole",
			"errors.validation.objLinkRequired": "Povinné pole",
			"errors.validation.xpsuiArrayUnique": "In the table there are two identical records",
			"errors.validation.psuiUnique": "Hodnota už v systéme existuje",
			"errors.validation.parse":"Nesprávny formát",
			"errors.validation.valid":"無効なフォーマットです",
			"schema.editor.schema.editing":"Úprava schémy",
			"security.group.edit.parent.group":"Rodičovská skupina",
			"schemas.group.base.data":"Základné údaje",
			"schemas.group.base.data.ID":"Identifikátor",
			"schemas.group.base.data.name":"Názov skupiny",
			"schemas.group.base.data.parent.group":"Rodičovská skupina",
			"schema.statistics.view":"Štatistiky",
			"schema.statistics.baseData":"Základné",
			"schema.statistics.member.count":"Počet členov",
			"schema.statistics.women.count":"Počet žien",
			"schema.statistics.fees":"Poplatky",
			"schema.statistics.fees.expected.count":"Počet vystavených",
			"schema.statistics.fees.expected.sum":"Suma vystavených",
			"schema.statistics.fees.paid.count":"Počet zaplatených",
			"schema.statistics.fees.paid.sum":"Suma zplatených",
			"schema.statistics.fees.overdue.count":"Počet po splatnosti",
			"schema.statistics.fees.overdue.sum":"Suma po splatnosti",
			"schema.requests.basedata": "Základné údaje",
			"schema.requests.basedata": "Prílohy",

			"schemas.countries.enum.AFG": "Afganistan",
			"schemas.countries.enum.ALA": "Alandy",
			"schemas.countries.enum.ALB": "Albánsko",
			"schemas.countries.enum.DZA": "Alžírsko",
			"schemas.countries.enum.ASM": "Americká Samoa",
			"schemas.countries.enum.VIR": "Americké Panenské ostrovy",
			"schemas.countries.enum.AND": "Andorra",
			"schemas.countries.enum.AGO": "Angola",
			"schemas.countries.enum.AIA": "Anguilla",
			"schemas.countries.enum.ATA": "Antarktída",
			"schemas.countries.enum.ATG": "Antigua a Barbuda",
			"schemas.countries.enum.ARG": "Argentína",
			"schemas.countries.enum.ARM": "Arménsko",
			"schemas.countries.enum.ABW": "Aruba",
			"schemas.countries.enum.AUS": "Austrália",
			"schemas.countries.enum.AZE": "Azerbajdžan",
			"schemas.countries.enum.BHS": "Bahamy",
			"schemas.countries.enum.BHR": "Bahrajn",
			"schemas.countries.enum.BGD": "Bangladéš",
			"schemas.countries.enum.BRB": "Barbados",
			"schemas.countries.enum.BEL": "Belgicko",
			"schemas.countries.enum.BLZ": "Belize",
			"schemas.countries.enum.BLR": "Bielorusko",
			"schemas.countries.enum.BEN": "Benin",
			"schemas.countries.enum.BMU": "Bermudy",
			"schemas.countries.enum.BTN": "Bhután",
			"schemas.countries.enum.BOL": "Bolívia",
			"schemas.countries.enum.BES": "Bonaire, Svätý Eustatius a Saba",
			"schemas.countries.enum.BIH": "Bosna a Hercegovina",
			"schemas.countries.enum.BWA": "Botswana",
			"schemas.countries.enum.BVT": "Bouvetov ostrov",
			"schemas.countries.enum.BRA": "Brazília",
			"schemas.countries.enum.IOT": "Britské indickooceánske územie",
			"schemas.countries.enum.VGB": "Britské Panenské ostrovy",
			"schemas.countries.enum.BRN": "Brunej",
			"schemas.countries.enum.BGR": "Bulharsko",
			"schemas.countries.enum.BFA": "Burkina Faso",
			"schemas.countries.enum.BDI": "Burundi",
			"schemas.countries.enum.COK": "Cookove ostrovy",
			"schemas.countries.enum.CUW": "Curaçao",
			"schemas.countries.enum.TCD": "Čad",
			"schemas.countries.enum.MNE": "Čierna Hora",
			"schemas.countries.enum.CZE": "Česko",
			"schemas.countries.enum.CHN": "Čína",
			"schemas.countries.enum.DNK": "Dánsko",
			"schemas.countries.enum.COD": "Kongo",
			"schemas.countries.enum.DMA": "Dominika",
			"schemas.countries.enum.DOM": "Dominikánska republika",
			"schemas.countries.enum.DJI": "Džibutsko",
			"schemas.countries.enum.EGY": "Egypt",
			"schemas.countries.enum.ECU": "Ekvádor",
			"schemas.countries.enum.ERI": "Eritrea",
			"schemas.countries.enum.EST": "Estónsko",
			"schemas.countries.enum.ETH": "Etiópia",
			"schemas.countries.enum.FRO": "Faerské ostrovy",
			"schemas.countries.enum.FLK": "Falklandy",
			"schemas.countries.enum.FJI": "Fidži",
			"schemas.countries.enum.PHL": "Filipíny",
			"schemas.countries.enum.FIN": "Fínsko",
			"schemas.countries.enum.FRA": "Francúzsko",
			"schemas.countries.enum.GUF": "Francúzska Guyana",
			"schemas.countries.enum.ATF": "Francúzske južné a antarktické územia",
			"schemas.countries.enum.PYF": "Francúzska Polynézia",
			"schemas.countries.enum.GAB": "Gabon",
			"schemas.countries.enum.GMB": "Gambia",
			"schemas.countries.enum.GHA": "Ghana",
			"schemas.countries.enum.GIB": "Gibraltár",
			"schemas.countries.enum.GRD": "Grenada",
			"schemas.countries.enum.GRL": "Grónsko",
			"schemas.countries.enum.GEO": "Gruzínsko",
			"schemas.countries.enum.GLP": "Guadeloupe",
			"schemas.countries.enum.GUM": "Guam",
			"schemas.countries.enum.GTM": "Guatemala",
			"schemas.countries.enum.GGY": "Guernsey",
			"schemas.countries.enum.GIN": "Guinea",
			"schemas.countries.enum.GNB": "Guinea-Bissau",
			"schemas.countries.enum.GUY": "Guyana",
			"schemas.countries.enum.HTI": "Haiti",
			"schemas.countries.enum.HMD": "Heardov ostrov a MacDonaldová ostrovy",
			"schemas.countries.enum.HND": "Honduras",
			"schemas.countries.enum.HKG": "Hongkong",
			"schemas.countries.enum.CHL": "Čile",
			"schemas.countries.enum.HRV": "Chorvátsko",
			"schemas.countries.enum.IND": "India",
			"schemas.countries.enum.IDN": "Indonézia",
			"schemas.countries.enum.IRQ": "Irak",
			"schemas.countries.enum.IRN": "Irán",
			"schemas.countries.enum.IRL": "Írsko",
			"schemas.countries.enum.ISL": "Island",
			"schemas.countries.enum.ITA": "Taliansko",
			"schemas.countries.enum.ISR": "Izrael",
			"schemas.countries.enum.JAM": "Jamajka",
			"schemas.countries.enum.JPN": "Japonsko",
			"schemas.countries.enum.YEM": "Jemen",
			"schemas.countries.enum.JEY": "Jersey",
			"schemas.countries.enum.ZAF": "Južná Afrika",
			"schemas.countries.enum.SGS": "Južná Georgia a Južné Sandwichove ostrovy",
			"schemas.countries.enum.SSD": "Južný Sudán",
			"schemas.countries.enum.JOR": "Jordánsko",
			"schemas.countries.enum.CYM": "Kajmanie ostrovy",
			"schemas.countries.enum.KHM": "Kambodža",
			"schemas.countries.enum.CMR": "Kamerun",
			"schemas.countries.enum.CAN": "Kanada",
			"schemas.countries.enum.CPV": "Kapverdy",
			"schemas.countries.enum.QAT": "Katar",
			"schemas.countries.enum.KAZ": "Kazachstan",
			"schemas.countries.enum.KEN": "Keňa",
			"schemas.countries.enum.KIR": "Kiribati",
			"schemas.countries.enum.CCK": "Kokosové (Keelingove) ostrovy",
			"schemas.countries.enum.COL": "Kolumbia",
			"schemas.countries.enum.COM": "Komory",
			"schemas.countries.enum.COG": "Konžská republika",
			"schemas.countries.enum.PRK": "Kórejská ľudovodemokratická republika",
			"schemas.countries.enum.KOR": "Kórejská republika",
			"schemas.countries.enum.XKO": "Kosovo",
			"schemas.countries.enum.CRI": "Kostarika",
			"schemas.countries.enum.CUB": "Kuba",
			"schemas.countries.enum.KWT": "Kuvajt",
			"schemas.countries.enum.CYP": "Cyprus",
			"schemas.countries.enum.KGZ": "Kirgizsko",
			"schemas.countries.enum.LAO": "Laos",
			"schemas.countries.enum.LSO": "Lesotho",
			"schemas.countries.enum.LBN": "Libanon",
			"schemas.countries.enum.LBR": "Libéria",
			"schemas.countries.enum.LBY": "Líbya",
			"schemas.countries.enum.LIE": "Lichtenštajnsko",
			"schemas.countries.enum.LTU": "Litva",
			"schemas.countries.enum.LVA": "Lotyšsko",
			"schemas.countries.enum.LUX": "Luxembursko",
			"schemas.countries.enum.MAC": "Macao",
			"schemas.countries.enum.MDG": "Madagaskar",
			"schemas.countries.enum.HUN": "Maďarsko",
			"schemas.countries.enum.MKD": "Macedónsko",
			"schemas.countries.enum.MYS": "Malajzia",
			"schemas.countries.enum.MWI": "Malawi",
			"schemas.countries.enum.MDV": "Maldivy",
			"schemas.countries.enum.MLI": "Mali",
			"schemas.countries.enum.MLT": "Malta",
			"schemas.countries.enum.IMN": "Man",
			"schemas.countries.enum.MAR": "Maroko",
			"schemas.countries.enum.MHL": "Marshallove ostrovy",
			"schemas.countries.enum.MTQ": "Martinik",
			"schemas.countries.enum.MUS": "Maurícius",
			"schemas.countries.enum.MRT": "Mauritánia",
			"schemas.countries.enum.MYT": "Mayotte",
			"schemas.countries.enum.UMI": "Menšie odľahlé ostrovy USA",
			"schemas.countries.enum.MEX": "Mexiko",
			"schemas.countries.enum.FSM": "Mikronézia",
			"schemas.countries.enum.MDA": "Moldavsko",
			"schemas.countries.enum.MCO": "Monako",
			"schemas.countries.enum.MNG": "Mongolsko",
			"schemas.countries.enum.MSR": "Montserrat",
			"schemas.countries.enum.MOZ": "Mozambik",
			"schemas.countries.enum.MMR": "Mjanmarsko",
			"schemas.countries.enum.NAM": "Namíbia",
			"schemas.countries.enum.NRU": "Nauru",
			"schemas.countries.enum.DEU": "Nemecko",
			"schemas.countries.enum.NPL": "Nepál",
			"schemas.countries.enum.NER": "Niger",
			"schemas.countries.enum.NGA": "Nigéria",
			"schemas.countries.enum.NIC": "Nikaragua",
			"schemas.countries.enum.NIU": "Niue",
			"schemas.countries.enum.NLD": "Holandsko",
			"schemas.countries.enum.NFK": "Norfolk",
			"schemas.countries.enum.NOR": "Nórsko",
			"schemas.countries.enum.NCL": "Nová Kaledónia",
			"schemas.countries.enum.NZL": "Nový Zéland",
			"schemas.countries.enum.OMN": "Omán",
			"schemas.countries.enum.PAK": "Pakistan",
			"schemas.countries.enum.PLW": "Palau",
			"schemas.countries.enum.PSE": "Palestína",
			"schemas.countries.enum.PAN": "Panama",
			"schemas.countries.enum.PNG": "Papua Nová Guinea",
			"schemas.countries.enum.PRY": "Paraguaj",
			"schemas.countries.enum.PER": "Peru",
			"schemas.countries.enum.PCN": "Pitcairn",
			"schemas.countries.enum.CIV": "Pobrežie Slonoviny",
			"schemas.countries.enum.POL": "Poľsko",
			"schemas.countries.enum.PRI": "Portoriko",
			"schemas.countries.enum.PRT": "Portugalsko",
			"schemas.countries.enum.AUT": "Rakúsko",
			"schemas.countries.enum.REU": "Réunion",
			"schemas.countries.enum.GNQ": "Rovníková Guinea",
			"schemas.countries.enum.ROU": "Rumunsko",
			"schemas.countries.enum.RUS": "Rusko",
			"schemas.countries.enum.RWA": "Rwanda",
			"schemas.countries.enum.GRC": "Grécko",
			"schemas.countries.enum.SPM": "Saint Pierre a Miquelon",
			"schemas.countries.enum.SLV": "Salvador",
			"schemas.countries.enum.WSM": "Samoa",
			"schemas.countries.enum.SMR": "San Marino",
			"schemas.countries.enum.SAU": "Saudská Arábia",
			"schemas.countries.enum.SEN": "Senegal",
			"schemas.countries.enum.MNP": "Severné Mariány",
			"schemas.countries.enum.SYC": "Seychely",
			"schemas.countries.enum.SLE": "Sierra Leone",
			"schemas.countries.enum.SGP": "Singapur",
			"schemas.countries.enum.SVK": "Slovensko",
			"schemas.countries.enum.SVN": "Slovinsko",
			"schemas.countries.enum.SOM": "Somálsko",
			"schemas.countries.enum.ARE": "Spojené arabské emiráty",
			"schemas.countries.enum.USA": "Spojené štáty",
			"schemas.countries.enum.SRB": "Srbsko",
			"schemas.countries.enum.CAF": "Stredoafrická republika",
			"schemas.countries.enum.SDN": "Sudán",
			"schemas.countries.enum.SUR": "Surinam",
			"schemas.countries.enum.SHN": "Svätá Helena",
			"schemas.countries.enum.LCA": "Svätá Lucia",
			"schemas.countries.enum.BLM": "Svätý Bartolomej",
			"schemas.countries.enum.KNA": "Svätý Krištof a Nevis",
			"schemas.countries.enum.MAF": "Svätý Martin (FR)",
			"schemas.countries.enum.SXM": "Svätý Martin (NL)",
			"schemas.countries.enum.STP": "Svätý Tomáš a Princov ostrov",
			"schemas.countries.enum.VCT": "Svätý Vincent a Grenadíny",
			"schemas.countries.enum.SWZ": "Svazijsko",
			"schemas.countries.enum.SYR": "Sýria",
			"schemas.countries.enum.SLB": "Šalamúnove ostrovy",
			"schemas.countries.enum.ESP": "Španielsko",
			"schemas.countries.enum.SJM": "Špicbergy a Jan Mayen",
			"schemas.countries.enum.LKA": "Srí Lanka",
			"schemas.countries.enum.SWE": "Švédsko",
			"schemas.countries.enum.CHE": "Švajčiarsko",
			"schemas.countries.enum.TJK": "Tadžikistan",
			"schemas.countries.enum.TZA": "Tanzánia",
			"schemas.countries.enum.THA": "Thajsko",
			"schemas.countries.enum.TWN": "Taiwan",
			"schemas.countries.enum.TGO": "Togo",
			"schemas.countries.enum.TKL": "Tokelau",
			"schemas.countries.enum.TON": "Tonga",
			"schemas.countries.enum.TTO": "Trinidad a Tobago",
			"schemas.countries.enum.TUN": "Tunisko",
			"schemas.countries.enum.TUR": "Turecko",
			"schemas.countries.enum.TKM": "Turkménsko",
			"schemas.countries.enum.TCA": "Turks a Caicos",
			"schemas.countries.enum.TUV": "Tuvalu",
			"schemas.countries.enum.UGA": "Uganda",
			"schemas.countries.enum.UKR": "Ukrajina",
			"schemas.countries.enum.URY": "Uruguaj",
			"schemas.countries.enum.UZB": "Uzbekistan",
			"schemas.countries.enum.CXR": "Vianočný ostrov",
			"schemas.countries.enum.VUT": "Vanuatu",
			"schemas.countries.enum.VAT": "Vatikán",
			"schemas.countries.enum.GBR": "Veľká Británia",
			"schemas.countries.enum.VEN": "Venezuela",
			"schemas.countries.enum.VNM": "Vietnam",
			"schemas.countries.enum.TLS": "Východný Timor",
			"schemas.countries.enum.WLF": "Wallis a Futuna",
			"schemas.countries.enum.ZMB": "Zambia",
			"schemas.countries.enum.ESH": "Západná Sahara",
			"schemas.countries.enum.ZWE": "Zimbabwe",

			"dashboard.widget.notifications.title": "Notifications",
			"dashboard.widget.members.openRequests": "Registration requests",
			"dashboard.widget.data.openRequests":"Data change requests",
			"dashboard.widget.transfer.openRequests":"Transfer requests",
			"dashboard.widget.members.noOpenRequests": "No requests.",
			"dashboard.widget.data.noOpenRequests": "No requests.",
			"dashboard.widget.transfer.noOpenRequests": "No requests.",

			"portal.edit.title":"Portal editor",
			"portal.edit.edit":"Edit",
			"portal.edit.new":"New",
			"portal.edit.add.block":"Add a block",
			"portal.edit.cancel":"Cancel",
			"portal.edit.save":"Save",
			"portal.edit.new.name":"Name:",
			"portal.edit.new.template":"Template:",
			"portal.edit.new.publish":"Publish:",
			"portal.edit.new.publishFrom":"Publish from:",
			"portal.edit.new.tags":"Tags:",
			"portal.edit.block.choice":"Block choice",
			"portal.edit.block.description":"Description:",
			"portal.menu.title":"Portal menu",
			"portal.menu.cancel":"Cancel",
			"portal.menu.send":"Send",
		});

		//fallback for individual missing transCodes
		$translateProvider.fallbackLanguage(['en']);

		$translateProvider.registerAvailableLanguageKeys(['en','cz','sk','ja','de'], {
			'en*': 'en',
			'de*': 'de',
			'ja*': 'ja',
			'cs*': 'cz',
			'sk*': 'sk',
			'*': 'en' // must be last
		});
		$translateProvider.determinePreferredLanguage();
		$translateProvider.useCookieStorage();


	}])
	.controller('langSelectCtrl', ['$scope', '$translate', function($scope, $translate) {
		$scope.setLang = function(lang) {
			$translate.use(lang);
		};
	}]);

}(angular));
