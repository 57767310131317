(function(angular) {
	'use strict';

	angular.module('x-registries', [
		'ngRoute',
		'ngCookies',
		'xpsui:filters',
		'xpsui:services',
		'xpsui:directives',
		'xpsui:controllers',
		'pascalprecht.translate',
		'ui.ace',
		'ja.qr',
		'ngStorage',

		// 'x-security',
		// 'personal-page',
		// 'psui-notification'
	])
	.config(['$httpProvider', function ($httpProvider) {
		$httpProvider.interceptors.push(function ($q, $injector, $rootScope) {
			return {
				'response': function (response) {
					//Will only be called for HTTP up to 300
					return response;
				},
				'responseError': function(rejection) {
					if (rejection.status === 500) {
						$injector.get('xpsui:NotificationFactory').warn({translationCode: 'server.side.exception', translationData: rejection.data, time: 5000});
					} else if (rejection.status === 401) {
						$rootScope.security.currentUser = undefined;
						$rootScope.app.mainMenu = false;
						$injector.get('xpsui:NavigationService').navigate();
						$injector.get('$location').url('/login');
						$injector.get('xpsui:NotificationFactory').warn({translationCode: 'security.user.session.expired', time: 5000});
					} else if (rejection.status === 403) {
						$injector.get('xpsui:NavigationService').navigate();
						$injector.get('$location').url('/login');
						$injector.get('xpsui:NotificationFactory').warn({translationCode: 'security.user.missing.permissions', translationData: rejection.data.missingPerm, time: 5000});
					}
					
					return $q.reject(rejection);
				}
			};
		});
	}])
	.config(['$routeProvider', 'xpsui:loggingProvider', function($routeProvider, loggingProvider) {
		// $routeProvider.when('/view/:schema/:objId', {controller: 'xViewController', templateUrl: '/partials/x-view.html'});

		$routeProvider.when('/dashboard', {templateUrl: '/partials/x-dashboard.html', controller: 'xpsui:DashboardCtrl', permissions:['System User']});
		$routeProvider.when('/personal-page', {templateUrl: '/partials/x-personal-page.html', controller: 'xpsui:PersonalPageCtrl', permissions:['System User']});
		$routeProvider.when('/statistics', {templateUrl: '/partials/x-registry-view.html', controller: 'xpsui:StatisticsViewCtrl', permissions:['Registry - read']});
		$routeProvider.when('/massmailing', {templateUrl: '/partials/x-massmailing.html', controller: 'xpsui:MassmailingEditCtrl', permissions:['Registry - write']});
		$routeProvider.when('/login', {templateUrl: '/partials/x-login.html', controller: 'xpsui:SecurityLoginCtrl'});
		$routeProvider.when('/personal-change-password', {templateUrl: '/partials/x-personal-change-password.html', controller: 'xpsui:SecurityPersonalChangePasswordCtrl', permissions:['System User']});
		$routeProvider.when('/security/group/edit/', {
			templateUrl: '/partials/x-security-group-edit.html',
			controller: 'xpsui:SecurityGroupEditCtrl',
			permissions:['Security - read'],
			resolve: {
				schema: ['xpsui:SchemaUtil', function (schemaUtilFactory) {
					return schemaUtilFactory.getCompiledSchema('uri://registries/security#groupmaster', 'new').then(function(response) {
						return response.data;
					});
				}]
			}
		});
		$routeProvider.when('/security/user/edit', {templateUrl: 'partials/x-security-user-edit.html', controller: 'xpsui:SecurityUserEditCtrl',permissions:['Security - read']});
		$routeProvider.when('/security/profile/edit', {templateUrl: 'partials/x-security-profile-edit.html', controller: 'xpsui:SecurityProfileEditCtrl',permissions:['Security - read']});

		$routeProvider.when('/forgotten', {templateUrl: '/partials/forgotten.html', controller: 'xpsui:SecurityForgottenCtrl'});
		$routeProvider.when('/forgotten/reset/:token', {templateUrl: '/partials/forgottenReset.html', controller: 'xpsui:SecurityForgottenResetCtrl'});

		$routeProvider.when('/registry/new/:schema', {templateUrl: '/partials/x-registry-new.html', controller: 'xpsui:RegistryNewCtrl'});
		$routeProvider.when('/registry/view/:schema/:id', {templateUrl: '/partials/x-registry-view.html', controller: 'xpsui:RegistryViewCtrl'});
		$routeProvider.when('/registry/search/:schema', {templateUrl : 'partials/x-registry-search.html', controller : 'xpsui:RegistrySearchCtrl'});

		$routeProvider.when('/2/registry/new/:schema', {templateUrl: '/partials/registry-new-v2.html', controller: 'xpsui:RegistryNewV2Ctrl'});
		$routeProvider.when('/2/registry/search/:schema', {templateUrl : 'partials/registry-search-v2.html', controller : 'xpsui:RegistrySearchV2Ctrl'});
		$routeProvider.when('/2/registry/view/:schema/:id', {templateUrl: '/partials/registry-view-v2.html', controller: 'xpsui:RegistryViewV2Ctrl'});
		$routeProvider.when('/2/online-payment/:schema/:id', {templateUrl: '/partials/online-payment.html', controller: 'xpsui:OnlinePayment'});

		$routeProvider.when('/registry/custom/:template/:schema/:id', {templateUrl: function(params) {
			return '/dataset/get/partials/' + params.template;
		}, controller: 'xpsui:RegistryCustomTemplateCtrl',permissions:['Registry - read']});

		$routeProvider.when('/registry/generated/:schemaFrom/:idFrom/:generateBy/:template', {templateUrl: function(params) {
			return '/dataset/get/partials/' + params.template;
		}, controller: 'xpsui:RegistryCustomGenerateCtrl',permissions:['Registry - read']});

		$routeProvider.when('/portal/edit/:id?', {templateUrl: '/partials/x-portal-edit.html', controller: 'xpsui:PortalEditorCtrl',permissions:['Portal - write']});
		$routeProvider.when('/portal/menu', {templateUrl: '/partials/x-portal-menu.html', controller: 'xpsui:PortalEditorMenuCtrl',permissions:['Portal - write']});
		$routeProvider.when('/portal/list', {templateUrl: '/partials/x-portal-list.html', controller: 'xpsui:PortalEditorListCtrl',permissions:['Portal - write']});

		$routeProvider.when('/schema/edit', {
			templateUrl: 'partials/x-schema-editor-index.html',
			controller: 'xpsui:SchemaEditorIndexCtrl',
			permissions: ['System Admin'],
			resolve: {
				schemas: ['xpsui:SchemaEditorService', function (schemaService) {
					return schemaService.getSchemaList().then(function (response) {
						return response.data;
					});
				}]
			}
		});

		$routeProvider.when('/schema/edit/:schema', {
			templateUrl: 'partials/x-schema-editor-show.html',
			controller: 'xpsui:SchemaEditorShowCtrl',
			permissions: ['System Admin'],
			resolve: {
				// Load schema from the server
				schema: ['$route', 'xpsui:SchemaEditorService', function ($route, schemaService) {
					return schemaService.getFileContent($route.current.params.schema).then(function (response) {
						return response.data;
					});
				}]
			}
		});
		$routeProvider.when('/help', {templateUrl: '/dataset/get/partials/x-help.html', controller: 'xpsui:HelpPageCtrl'});
		$routeProvider.otherwise('/login', {templateUrl: '/partials/x-login.html', controller: 'xpsui:SecurityLoginCtrl'});
		loggingProvider.setLevel(5);
	}])
	/**
	 * Main function, initializes all required data and scopes. For configuration of $providers
	 * use .config
	 */
	.run(["$rootScope", '$location', 'xpsui:SecurityService', '$cookies','xpsui:NotificationFactory', '$window', 'xpsui:config', function($rootScope, $location, SecurityService,$cookies,notificationFactory, $window, config) {
		if($window.navigator && $window.navigator.userAgent) {
			if($window.navigator.userAgent.indexOf("Crosswalk") > -1 ) {
				$rootScope.isMobile = true;
				$rootScope.isiOS = false;
				$rootScope.isWP = false;
			} else if($window.navigator.userAgent.match(/iPad/i) || $window.navigator.userAgent.match(/iPhone/i)) {
				$rootScope.isiOS = true;
				$rootScope.isMobile = false;
			} else if($window.navigator.userAgent.match(/Windows Phone/i) || window.navigator.userAgent.match(/imobile/i)) {
				$rootScope.isiOS = false;
				$rootScope.isMobile = false;
				$rootScope.isWP = true;
			} else {
				$rootScope.isMobile = false;
				$rootScope.isiOS = false;
				$rootScope.isWP = false;
			}
		} else {
			$rootScope.isMobile = false;
		}
		$rootScope.security = $rootScope.security || {};
		// by default, current user is undefined, as there is noone logged in
		$rootScope.hasPermissions=SecurityService.hasPermissions;
		var changeRouteRuleActive=false;

		if ($cookies.loginName){
			SecurityService.getCurrentUser()
			.success(function(data, status, headers, sconfig) {
				$rootScope.security.currentUser = data;
				changeRouteRuleActive=true;
				if ($location.url() === '/login') {
					$location.url(config.get('after_login_url') || '/dashboard');
				}
			})
			.error(function(data, status, headers, sconfig) {
				delete $rootScope.security.currentUser;
				$rootScope.security.currentUser = undefined;
				changeRouteRuleActive=true;
				$location.path('/login');
			});

		}
		else {
			$rootScope.security.currentUser = undefined;
			changeRouteRuleActive=true;
		}

		$rootScope.$on('$routeChangeStart', function() {
			notificationFactory.clear();
		});

		// hang on route change, so we can check if user meets security criteria
		$rootScope.$on('$routeChangeStart', function(evt, nextRoute, currentRoute) {
			if (nextRoute && nextRoute.permissions) {
				// check permissions only if defined
				if (changeRouteRuleActive && (!$rootScope.security.currentUser || !SecurityService.hasPermissions(nextRoute.permissions))) {
					notificationFactory.warn({translationCode:'security.user.missing.permissions',translationData: nextRoute.permissions ,time:5000});
					$location.url('/login');
				}
			}
		});

		$rootScope.app = {
			mainMenu: false
		};
	}]);


}(angular));
